import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  ClaimId: any;
  /** Date, given as an ISO 8601 string. Parsed as UTC if time offset is not given. */
  Date: any;
  DeviceId: any;
  /**
   * Global location number, used world wide to identify legal, physical and
   * functional locations. These are used as identifiers e.g. companies and power
   * meter ids.
   */
  Gln: any;
  IntermediaryId: any;
  JSON: any;
  MeterId: any;
  /** Nothing */
  Void: any;
};


export type Activation = {
  __typename?: 'Activation';
  status: ActivationStatus;
  updatedAt: Scalars['Date'];
};

export type ActivationStatus =
  | 'ACTIVATED'
  | 'DEACTIVATED';

export type ActiveEnergyImportedRecord = {
  __typename?: 'ActiveEnergyImportedRecord';
  startTime: Scalars['Date'];
  wh: Scalars['Int'];
};

export type ActiveEnergyImportedRecordInput = {
  startTime: Scalars['Date'];
  wh: Scalars['Int'];
};

export type AddBrightNotificationChannelToAlertSettingInput = {
  alertSettingId: Scalars['ID'];
};

export type AddBrightNotificationChanneltToAlertSettingPayload = {
  __typename?: 'AddBrightNotificationChanneltToAlertSettingPayload';
  notificationChannel: BrightNotificationChannel;
};

export type AddChargingLocationInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  name: Scalars['String'];
};

export type AddChargingLocationPayload = AddChargingLocationSuccessPayload | ChargingLocationAlreadyExistsPayload;

export type AddChargingLocationResponseType =
  | 'SUCCESS'
  | 'ALREADY_EXISTS';

export type AddChargingLocationSuccessPayload = {
  __typename?: 'AddChargingLocationSuccessPayload';
  chargingLocation: ChargingLocation;
  type: AddChargingLocationResponseType;
};

export type AddChargingPermissionInput = {
  userIdentifier: UserIdentifierInput;
};

export type AddChargingPermissionPayload = {
  __typename?: 'AddChargingPermissionPayload';
  modifiedPermission: Permission;
  success: Scalars['Boolean'];
  user: UserInfo;
};

export type AddControllableForLocationInput = {
  locationId: Scalars['String'];
  controllableId: Scalars['String'];
};

export type AddControllableForLocationPayload = {
  __typename?: 'AddControllableForLocationPayload';
  location: Location;
};

export type AddDevicePermissionsInput = {
  deviceIdentifiers: Array<DeviceIdentifierInput>;
  userIdentifier: UserIdentifierInput;
};

export type AddDevicePermissionsPayload = {
  __typename?: 'AddDevicePermissionsPayload';
  modifiedPermissions: Array<Permission>;
  success: Scalars['Boolean'];
  user: UserInfo;
};

export type AddEstimatedHourlyActiveEnergyLimitWarningAlertSettingInput = {
  deviceId: Scalars['String'];
  limitInWattHours: Scalars['Int'];
};

export type AddHourlyConsumptionLimitEstimationWarningAlertSettingInput = {
  deviceId: Scalars['String'];
  limitInWatts: Scalars['Int'];
};

export type AddIntermediaryApiKeyInput = {
  /** The name of the api key. This is to easier keep track of keys. */
  name: Scalars['String'];
};

export type AddIntermediaryWebhookNotificationChannelToAlertSettingInput = {
  alertSettingId: Scalars['ID'];
  callbackUrl: Scalars['String'];
  metadata?: Maybe<Scalars['JSON']>;
  intermediaryId?: Maybe<Scalars['String']>;
};

export type AddIntermediaryWebhookNotificationChannelToAlertSettingPayload = {
  __typename?: 'AddIntermediaryWebhookNotificationChannelToAlertSettingPayload';
  notificationChannel: IntermediaryWebhookNotificationChannel;
};

export type AddLocationInput = {
  name: Scalars['String'];
  coordinates: LatLngInput;
};

export type AddLocationPayload = {
  __typename?: 'AddLocationPayload';
  location: Location;
};

export type AddPriceWarningAlertSettingInput = {
  energyBiddingZone: EnergyBiddingZone;
};

export type AddPushNotificationChannelToAlertSettingInput = {
  alertSettingId: Scalars['ID'];
};

export type AddPushNotificationChannelToAlertSettingPayload = {
  __typename?: 'AddPushNotificationChannelToAlertSettingPayload';
  notificationChannel: PushNotificationChannel;
};

export type AddUserInput = {
  userIdentifier: UserIdentifierInput;
};

export type AddUserPayload = {
  __typename?: 'AddUserPayload';
  success: Scalars['Boolean'];
  user: UserInfo;
};

export type AddUserSessionInput = {
  userIdentifier: UserIdentifierInput;
};

export type AddUserSessionPayload = {
  __typename?: 'AddUserSessionPayload';
  session: SessionInfo;
  success: Scalars['Boolean'];
  user: UserInfo;
};

export type AddressInput = {
  address?: Maybe<Scalars['String']>;
  postalCode: Scalars['String'];
  countryCode: CountryCode;
};

export type AlertChannelType =
  | 'BRIGHT_NOTIFICATION'
  | 'PUSH_NOTIFICATION'
  | 'INTERMEDIARY_WEBHOOK';

/** Types of alert events. */
export type AlertEvent = HourlyConsumptionWarningEvent | HourlyConsumptionReachedEvent | EstimatedConsumptionWarningEvent | PriceWarningEvent;

export type AlertEventType =
  | 'HOURLY_CONSUMPTION_LIMIT_ESTIMATION_WARNING'
  | 'HOURLY_CONSUMPTION_REACHED'
  | 'ESTIMATED_HOURLY_ACTIVE_ENERGY_LIMIT_WARNING'
  | 'PRICE_WARNING';

export type AlertEventsOfChannelTypeInput = {
  deviceId?: Maybe<Scalars['String']>;
  notificationChannelType: NotificationChannelType;
};

export type AlertEventsOfChannelTypePayload = {
  __typename?: 'AlertEventsOfChannelTypePayload';
  events: Array<AlertEvent>;
};

export type AlertEventsPayload = {
  __typename?: 'AlertEventsPayload';
  events: Array<AlertEvent>;
};

/** An alert setting is a configuration that defines what type, when, or how an alert should be triggered. */
export type AlertSetting = PriceWarningAlertSetting | HourlyConsumptionLimitEstimationWarningAlertSetting | EstimatedHourlyActiveEnergyLimitWarningAlertSetting;

export type AlertSettingCreator = EndUserAlertSettingCreator | IntermediaryAlertSettingCreator;

export type AlertSettingCreatorType =
  | 'END_USER'
  | 'INTERMEDIARY';

export type AlertType =
  | 'PRICE_WARNING'
  | 'HOURLY_CONSUMPTION_LIMIT_ESTIMATION_WARNING'
  | 'ESTIMATED_HOURLY_ACTIVE_ENERGY_LIMIT_WARNING';

export type BrightNotificationChannel = {
  __typename?: 'BrightNotificationChannel';
  forUserWithId: Scalars['String'];
  type: NotificationChannelType;
};

export type CancelOrderInput = {
  orderId: Scalars['String'];
};

export type CapacityCost = {
  __typename?: 'CapacityCost';
  cost: PriceSpecification;
  capacityStep?: Maybe<CapacityStep>;
};

export type CapacityModelBusinessType =
  | 'MonthlyPeaksAtDifferentDaysBusiness'
  | 'WeightedYearlyRollingPeaksInDifferentWeeksBusiness'
  | 'FuseAndVoltageSizeBusiness'
  | 'ConstantBusiness';

export type CapacityModelType =
  | 'MonthlyPeaksAtDifferentDays'
  | 'WeightedYearlyRollingPeaksInDifferentWeeks'
  | 'MonthlyPeaksAtDifferentDaysLinearWithBase'
  | 'FuseAndVoltageSize'
  | 'FuseAndHomeType'
  | 'FuseAndHomeBasedSelectableSeasonal';

/** The capacity steps includes as range in Wh and a price for the capacity step */
export type CapacityStep = {
  __typename?: 'CapacityStep';
  rangeInWh: EnergyRangeInWh;
  capacityStepPrice: PriceSpecification;
};

export type ChargeCost = {
  __typename?: 'ChargeCost';
  savings: Scalars['Float'];
  cost: Scalars['Float'];
  currency?: Maybe<Currency>;
};

/**
 * Type ChargeSettings
 * - `id` is the id of the charge settings.
 * - `vehicleId` is the id of the vehicle.
 * - `departureTimes` is a list of departure times for each day of the week.
 * - `desiredBatteryLevel` is the battery level at which the car should be charged at the departure time.
 * - `directChargingBatteryLevel` is the battery level at which the car should start charging immediately.
 * - `enableSmartChargingAt` is the date at which smart charging should be enabled.
 * - `isSmartChargingEnabled` is whether smart charging is enabled.
 * - `isSolarChargingEnabled` is whether solar charging is enabled.
 * - `maxAllowedTargetSoC` is the maximum allowed target state of charge.
 * - `timeZone` is the time zone of the user.
 */
export type ChargeSettings = {
  __typename?: 'ChargeSettings';
  id: Scalars['String'];
  vehicleId: Scalars['String'];
  departureTimes: DepartureTimes;
  isSmartChargingEnabled: Scalars['Boolean'];
  enableSmartChargingAt?: Maybe<Scalars['Date']>;
  timeZone: Timezone;
  directChargingBatteryLevel: Scalars['Float'];
  desiredBatteryLevel: Scalars['Float'];
  maxAllowedTargetSoC: Scalars['Float'];
  defaultStartBatteryLevel: Scalars['Float'];
  isSolarChargingEnabled: Scalars['Boolean'];
};

export type ChargingHistoryEntry = {
  __typename?: 'ChargingHistoryEntry';
  startTime: Scalars['Date'];
  endTime: Scalars['Date'];
  chargedEnergyInKwh: Scalars['Float'];
};

export type ChargingLocation = {
  __typename?: 'ChargingLocation';
  id: Scalars['ID'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  name: Scalars['String'];
};

export type ChargingLocationAlreadyExistsPayload = {
  __typename?: 'ChargingLocationAlreadyExistsPayload';
  type: AddChargingLocationResponseType;
};

export type ChargingSession = {
  __typename?: 'ChargingSession';
  sessionId: Scalars['String'];
  chargingLocationId: Scalars['String'];
  isSmartSession: Scalars['Boolean'];
  energy: Array<Maybe<ChargingHistoryEntry>>;
  chargeCost: ChargeCost;
};

export type ChargingStatisticsEntry = {
  __typename?: 'ChargingStatisticsEntry';
  kw: Range;
  kwhSum: Scalars['Float'];
  price: Range;
  nonSmartPrice: Range;
  costSum: Scalars['Float'];
  estimatedSavings: Scalars['Float'];
  date: Scalars['String'];
  smartSession: Scalars['Boolean'];
  priceCurrency: Currency;
};

export type ChargingStatisticsInput = {
  startDate: Scalars['Date'];
  endDate?: Maybe<Scalars['Date']>;
  type?: Maybe<ChargingStatisticsType>;
  resolution?: Maybe<ChargingStatisticsResolution>;
  id?: Maybe<Scalars['String']>;
  utcOffset?: Maybe<Scalars['Float']>;
  chargingLocationId?: Maybe<Scalars['String']>;
};

export type ChargingStatisticsPayload = {
  __typename?: 'ChargingStatisticsPayload';
  entries: Array<ChargingStatisticsEntry>;
};

export type ChargingStatisticsResolution =
  | 'HALF_HOUR'
  | 'HOUR'
  | 'DAY'
  | 'WEEK'
  | 'MONTH'
  | 'YEAR';

export type ChargingStatisticsType =
  | 'VEHICLE'
  | 'CHARGER'
  | 'HVAC';

export type CheckJedlixConnectionInput = {
  connectionId: Scalars['String'];
};


export type CommonSignInInput = {
  username: Scalars['String'];
  password: Scalars['String'];
  vendor: CommonSignInVendor;
};

export type CommonSignInPayload = {
  __typename?: 'CommonSignInPayload';
  success: Scalars['Boolean'];
};

export type CommonSignInVendor =
  | 'MILL';

export type ConfiguredIntegrationVendorDevice = ConfiguredIntegrationVendorVehicle;

export type ConfiguredIntegrationVendorInfo = {
  __typename?: 'ConfiguredIntegrationVendorInfo';
  status: IntegrationVendorStatus;
  vendor: IntegrationVendor;
  devices: Array<ConfiguredIntegrationVendorDevice>;
};

export type ConfiguredIntegrationVendorVehicle = {
  __typename?: 'ConfiguredIntegrationVendorVehicle';
  id: Scalars['ID'];
  vendor: IntegrationVendor;
  model: Scalars['String'];
  yearOfProduction: Scalars['Int'];
  isReachable: Scalars['Boolean'];
  isSmartChargingCapable: Scalars['Boolean'];
  chargingLocationId?: Maybe<Scalars['String']>;
  chargeState: ConfiguredIntegrationVendorVehicleChargeState;
  location: ConfiguredIntegrationVendorVehicleLocationState;
  smartChargingPolicy?: Maybe<SmartChargingPolicy>;
};

export type ConfiguredIntegrationVendorVehicleChargeState = {
  __typename?: 'ConfiguredIntegrationVendorVehicleChargeState';
  batteryCapacity: Scalars['Float'];
  batteryLevel: Scalars['Int'];
  chargeRate: Scalars['Float'];
  chargeTimeRemaining: Scalars['Int'];
  isCharging: Scalars['Boolean'];
  isPluggedIn: Scalars['Boolean'];
  range: Scalars['Float'];
  isFullyCharged: Scalars['Boolean'];
};

export type ConfiguredIntegrationVendorVehicleLocationState = GpsNeverActivatedConfiguredIntegrationVendorVehicleLocationState | GpsDisabledConfiguredIntegrationVendorVehicleLocationState | LocationAvailableConfiguredIntegrationVendorVehicleLocationState;

export type ConfiguredIntegrationVendorVehicleLocationStateType =
  | 'GPS_NEVER_ACTIVATED'
  | 'GPS_DISABLED'
  | 'LOCATION_AVAILABLE';

export type ConnectExternalUserInput = {
  userIdentifier: UserIdentifierInput;
};

export type ConnectExternalUserPayload = {
  __typename?: 'ConnectExternalUserPayload';
  /** Devices shipped to the user. If no devices have been shipped yet, the list is empty. Use the `ordersForUser` query to know the status of the user's orders. */
  devices: Array<DeviceInfo>;
  /** The sessions object contains an `accessToken` to be used by apps to run queries on behalf of the external user. This token will have permissions to get data for the devices shipped to the user. */
  session: SessionInfo;
  success: Scalars['Boolean'];
  user: UserInfo;
};

export type ConsideringSmartChargingStatus = {
  __typename?: 'ConsideringSmartChargingStatus';
  type: SmartChargingStatusType;
  consideration: SmartChargingConsideration;
  externalStart: Scalars['Boolean'];
};

/**
 * Capacity model where grid voltage and/or fuse size make the capacity steps.
 *
 * Capacity model used by Alut, NettiNord, SuNett.
 */
export type ConstantBusiness = {
  __typename?: 'ConstantBusiness';
  type: CapacityModelBusinessType;
  price: PriceSpecification;
  yearlyEnergyFund: PriceSpecification;
};

/** Energy model with same price for all times of the day, week and year */
export type ConstantPrice = {
  __typename?: 'ConstantPrice';
  type: EnergyModelType;
  pricesAndTaxes: PricesAndTaxes;
  rushPricing?: Maybe<Array<Maybe<RushPricingPeriod>>>;
};

/** Energy model with same price for all times of the day, week and year */
export type ConstantPriceBusiness = {
  __typename?: 'ConstantPriceBusiness';
  type: EnergyModelBusinessType;
  pricesAndTaxes: PricesAndTaxesBusiness;
  rushPricing?: Maybe<Array<Maybe<RushPricingPeriod>>>;
};

export type ConstantPriceDemandTariff = {
  __typename?: 'ConstantPriceDemandTariff';
  pricePerKw: PriceSpecification;
};

export type ConstantPriceWeightedDemandTariff = {
  __typename?: 'ConstantPriceWeightedDemandTariff';
  pricePerKw: PriceSpecification;
  monthWeights: MonthWeights;
};

export type ConsumerType =
  | 'PRIVATE'
  | 'BUSINESS';

export type ConsumerTypeEnterprise =
  | 'EnterpriseLowVoltage'
  | 'EnterpriseHighVoltage';

export type Controllable = {
  __typename?: 'Controllable';
  id: Scalars['String'];
  intermediaryId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  type: ControllableType;
  vendor: Vendor;
  ownerId: Scalars['String'];
};

export type ControllableType =
  | 'ROOM';

export type ControllablesForUserPayload = {
  __typename?: 'ControllablesForUserPayload';
  controllables: Array<Controllable>;
};

export type CountryCode =
  | 'NO';

/**
 * Input object for creating charge settings.
 * - `defaultStartBatteryLevel` can be set as a default battery level for the beginning of a session, if we cannot get the SoC from the vehicle this value will be used.
 * - `departureTimes` is a list of departure times for each day of the week.
 * - `desiredBatteryLevel` is the battery level at which the car should be charged at the departure time.
 * - `directChargingBatteryLevel` is the battery level at which the car should start charging immediately.
 * - `enableSmartChargingAt` is the date at which smart charging should be enabled.
 * - `isSmartChargingEnabled` is whether smart charging is enabled.
 * - `isSolarChargingEnabled` is whether solar charging is enabled.
 * - `maxAllowedTargetSoC` is the maximum allowed target state of charge.
 * - `timeZone` is the time zone of the user.
 * - `vehicleId` is the id of the vehicle.
 */
export type CreateChargeSettingsInput = {
  defaultStartBatteryLevel?: Maybe<Scalars['Float']>;
  departureTimes: DepartureTimesInput;
  desiredBatteryLevel: Scalars['Float'];
  directChargingBatteryLevel: Scalars['Float'];
  enableSmartChargingAt?: Maybe<Scalars['Date']>;
  isSmartChargingEnabled: Scalars['Boolean'];
  isSolarChargingEnabled: Scalars['Boolean'];
  maxAllowedTargetSoC: Scalars['Float'];
  timeZone: Timezone;
  vehicleId: Scalars['String'];
};

/** Response object when creating charge settings. */
export type CreateChargeSettingsPayload = {
  __typename?: 'CreateChargeSettingsPayload';
  chargeSettings: ChargeSettings;
};

export type CreateJedlixChargingLocationInput = {
  coordinates: LatLngInput;
  address: JedlixAddressInput;
};

export type CreateJedlixUserInput = {
  locale: Scalars['String'];
  defaultCountry: CountryCode;
};

export type CreationInfo = {
  __typename?: 'CreationInfo';
  by: UserInfo;
  at: Scalars['Date'];
};

export type Currency =
  | 'NOK'
  | 'SEK';

export type CurrentIntermediaryPayload = {
  __typename?: 'CurrentIntermediaryPayload';
  alertSettings?: Maybe<Array<AlertSetting>>;
};


export type CurrentIntermediaryPayloadAlertSettingsArgs = {
  input?: Maybe<IntermediaryAlertSettingsInput>;
};

export type CurrentRangeMonthlyPrice = {
  __typename?: 'CurrentRangeMonthlyPrice';
  /** From, exclusive */
  fromCurrent: Scalars['Int'];
  /** To, inclusive */
  toCurrent: Scalars['Int'];
  /** Price for this current range */
  monthlyPrice: PriceSpecification;
};

export type CurrentRangePrice = {
  __typename?: 'CurrentRangePrice';
  /** From, exclusive */
  fromCurrent: Scalars['Int'];
  /** To, inclusive */
  toCurrent: Scalars['Int'];
  /** Price for this current range */
  yearlyPrice: PriceSpecification;
};

export type CurrentRangeWithSelectable = {
  __typename?: 'CurrentRangeWithSelectable';
  fromCurrent: Scalars['Int'];
  toCurrent: Scalars['Int'];
  selectableEnergyModel: SelectableEnergyModel;
};

export type CurrentRangeWithSelectableBusiness = {
  __typename?: 'CurrentRangeWithSelectableBusiness';
  fromCurrent: Scalars['Int'];
  toCurrent: Scalars['Int'];
  selectableEnergyModel: SelectableEnergyModelBusiness;
};

export type CustomError = {
  message: Scalars['String'];
  type: ErrorType;
};

export type DataInput = {
  records: Array<ActiveEnergyImportedRecordInput>;
};


export type DateRange = {
  __typename?: 'DateRange';
  /** Day of month and month as a number, including this date */
  from: DayAndMonth;
  /** Day of month and month as a number, excluding this date */
  until: DayAndMonth;
};

export type DayAndMonth = {
  __typename?: 'DayAndMonth';
  day: Scalars['Int'];
  month: Month;
};

/**
 * There may be different prices for day and night, maybe in combination with
 * seasonal (summer/winter) prices. Usually the day is from 6 to 22, but not
 * always.
 */
export type DayAndNightPrices = {
  __typename?: 'DayAndNightPrices';
  day: PricesAndTaxesForPartOfDay;
  night: PricesAndTaxesForPartOfDay;
};

/**
 * There may be different prices for day and night, maybe in combination with
 * seasonal (summer/winter) prices. Usually the day is from 6 to 22, but not
 * always.
 */
export type DayAndNightPricesBusiness = {
  __typename?: 'DayAndNightPricesBusiness';
  day: PricesAndTaxesForPartOfDayBusiness;
  night: PricesAndTaxesForPartOfDayBusiness;
};

export type DeleteAlertSettingInput = {
  id: Scalars['ID'];
};

export type DeleteAlertSettingPayload = {
  __typename?: 'DeleteAlertSettingPayload';
  success: Scalars['Boolean'];
};

export type DeleteChargingLocationInput = {
  chargingLocationId: Scalars['String'];
};

export type DeleteChargingLocationPayload = {
  __typename?: 'DeleteChargingLocationPayload';
  success: Scalars['Boolean'];
};

export type DeleteJedlixChargingLocationInput = {
  chargingLocationId: Scalars['String'];
};

export type DeleteJedlixChargingLocationPayload = {
  __typename?: 'DeleteJedlixChargingLocationPayload';
  response?: Maybe<Scalars['Void']>;
};

export type DeleteJedlixUserPayload = {
  __typename?: 'DeleteJedlixUserPayload';
  response?: Maybe<Scalars['Void']>;
};

export type DeleteJedlixVehicleInput = {
  vehicleId: Scalars['String'];
};

export type DeleteJedlixVehiclePayload = {
  __typename?: 'DeleteJedlixVehiclePayload';
  response?: Maybe<Scalars['Void']>;
};

export type DeleteMeInput = {
  accessToken: Scalars['String'];
  userId: Scalars['String'];
};

export type DeleteMePayload = {
  __typename?: 'DeleteMePayload';
  success: Scalars['Boolean'];
  user: UserInfo;
};

export type DeliveryChargeArea = {
  __typename?: 'DeliveryChargeArea';
  id: DeliveryChargeModelId;
};

export type DeliveryChargeAreaInput = {
  addressInput?: Maybe<AddressInput>;
  latLngInput?: Maybe<LatLngInput>;
};

export type DeliveryChargeCapacityBusinessModel = MonthlyPeaksAtDifferentDaysBusiness | WeightedYearlyRollingPeaksInDifferentWeeksBusiness | FuseAndVoltageSizeBusiness | ConstantBusiness;

export type DeliveryChargeCapacityModel = MonthlyPeaksAtDifferentDays | WeightedYearlyRollingPeaksInDifferentWeeks | MonthlyPeaksAtDifferentDaysLinearWithBase | FuseAndVoltageSize | FuseAndHomeType | FuseAndHomeBasedSelectableSeasonal;

export type DeliveryChargeCost = {
  __typename?: 'DeliveryChargeCost';
  capacityCost: CapacityCost;
  energyCost: EnergyCost;
};

export type DeliveryChargeCostBusiness = {
  __typename?: 'DeliveryChargeCostBusiness';
  capacityCost: CapacityCost;
  energyCost: EnergyCost;
};

export type DeliveryChargeCostBusinessInput = {
  deliveryChargeModelId?: Maybe<DeliveryChargeModelId>;
  gln?: Maybe<Scalars['Gln']>;
  dataInput: DataInput;
};

export type DeliveryChargeCostEnterprise = {
  __typename?: 'DeliveryChargeCostEnterprise';
  fixedFeeCost: FixedFeeCost;
  demandTariffCost: DemandTariffCost;
  energyCost: EnergyCost;
};

export type DeliveryChargeCostEnterpriseInput = {
  consumerType: ConsumerTypeEnterprise;
  deliveryChargeModelId?: Maybe<DeliveryChargeModelId>;
  gln?: Maybe<Scalars['Gln']>;
  dataInput: DataInput;
};

export type DeliveryChargeCostInput = {
  deliveryChargeModelId?: Maybe<DeliveryChargeModelId>;
  gln?: Maybe<Scalars['Gln']>;
  dataInput: DataInput;
};

export type DeliveryChargeDemandTariffEnterpriseModel = ConstantPriceDemandTariff | ConstantPriceWeightedDemandTariff | SeasonalPricesDemandTariff | TieredDemandTariff | SeasonalTieredDemandTariff;

export type DeliveryChargeEnergyBusinessModel = ConstantPriceBusiness | DemandChargeStepBusiness | DemandChargeStepWithSeasonsBusiness | DifferentPricesDayAndNightBusiness | DifferentPricesSeasonalBusiness | DifferentPricesSeasonalDayAndNightBusiness | FuseBasedSelectableBusiness | SelectableEnergyModelBusiness;

export type DeliveryChargeEnergyEnterpriseModel = ConstantPriceBusiness | DifferentPricesDayAndNightBusiness | DifferentPricesSeasonalBusiness;

export type DeliveryChargeEnergyModel = ConstantPrice | DifferentPricesDayAndNight | DifferentPricesSeasonal | DifferentPricesSeasonalDayAndNight | FuseBasedSelectable | SelectableEnergyModel | HomeBasedSelectableSeasonal;

/** Energy taxes (electrical power tax and energy fund tax) */
export type DeliveryChargeEnergyTaxes = {
  __typename?: 'DeliveryChargeEnergyTaxes';
  /** Electrical power tax (Avgift på elektrisk kraft, also called "forbruksavgift") */
  electricalPowerTax: PriceSpecification;
  /** Tax to the energy fund (Enova-avgift) */
  energyFund: PriceSpecification;
};

/** Energy taxes for business (electrical power tax) */
export type DeliveryChargeEnergyTaxesBusiness = {
  __typename?: 'DeliveryChargeEnergyTaxesBusiness';
  /** Electrical power tax (Avgift på elektrisk kraft, also called "forbruksavgift") */
  electricalPowerTax: PriceSpecification;
};

export type DeliveryChargeFixedFeeEnterpriseModel = PerMonthFixedFee | PerYearDividedByDaysFixedFee;

/** Return type for delivery charge models of type private */
export type DeliveryChargeModel = {
  __typename?: 'DeliveryChargeModel';
  id: DeliveryChargeModelId;
  capacityModel: DeliveryChargeCapacityModel;
  gln: Scalars['Gln'];
  energyModel: DeliveryChargeEnergyModel;
  taxesApplicable: DeliveryChargeTaxesApplicable;
  timezone: Timezone;
  validFrom: Scalars['Date'];
};

/** Return type for delivery charge models of type business */
export type DeliveryChargeModelBusiness = {
  __typename?: 'DeliveryChargeModelBusiness';
  id: DeliveryChargeModelId;
  capacityModel: DeliveryChargeCapacityBusinessModel;
  gln: Scalars['Gln'];
  energyModel: DeliveryChargeEnergyBusinessModel;
  taxesApplicable: DeliveryChargeTaxesApplicable;
  timezone: Timezone;
  validFrom: Scalars['Date'];
};

/** Input for delivery charge models of type business using `gln` or our `id` */
export type DeliveryChargeModelBusinessInput = {
  id?: Maybe<DeliveryChargeModelId>;
  gln?: Maybe<Scalars['Gln']>;
  date?: Maybe<Scalars['Date']>;
};

export type DeliveryChargeModelEnterprise = {
  __typename?: 'DeliveryChargeModelEnterprise';
  id: DeliveryChargeModelId;
  gln: Scalars['Gln'];
  demandTariffModel: DeliveryChargeDemandTariffEnterpriseModel;
  energyModel: DeliveryChargeEnergyEnterpriseModel;
  fixedFeeModel: DeliveryChargeFixedFeeEnterpriseModel;
  taxesApplicable: DeliveryChargeTaxesApplicable;
  timezone: Timezone;
  validFrom: Scalars['Date'];
};

export type DeliveryChargeModelId =
  | 'AGDER_ENERGI_NETT'
  | 'ALE_EL'
  | 'ALUT'
  | 'ANDOY_NETT'
  | 'ARVA'
  | 'ARVIKA_TEKNIK'
  | 'ASKER_NETT'
  | 'AUSTEVOLL_KRAFTLAG'
  | 'BARENTS_NETT'
  | 'BENGTSFORS_ENERGI_NAT'
  | 'BINDAL_KRAFTLAG'
  | 'BJARE_KRAFT'
  | 'BKK_NETT'
  | 'BLASJON_NAT'
  | 'BOMLO_KRAFTNETT'
  | 'BOO_ENERGI'
  | 'BREHEIM_NETT'
  | 'BRITTEDALS_ELNAT'
  | 'DE_NETT'
  | 'ELINETT'
  | 'ELLEVIO'
  | 'ELMEA'
  | 'ELVENETT'
  | 'ELVIA'
  | 'ELVERKET_HOLAND'
  | 'ENIDA'
  | 'ETNA'
  | 'EVERKET'
  | 'FAGNE'
  | 'FALU_ELNAT'
  | 'FILIPSTAD_ENERGINAT'
  | 'FJELLNETT'
  | 'FOIE'
  | 'FORE'
  | 'FUSA_KRAFTLAG'
  | 'GISLAVED_ENERGI'
  | 'GLITRE_ENERGI_NETT'
  | 'GLITRE_NETT'
  | 'GOTLANDS_ENERGI'
  | 'GRIUG'
  | 'HALLINGDAL_KRAFTNETT'
  | 'HALLSTAVIKS_ELVERK'
  | 'HALOGALAND_KRAFT_NETT'
  | 'HARJEANS'
  | 'HARRYDA_ENERGI'
  | 'HAVNETT'
  | 'HEMSIL'
  | 'HJARTUMS_ELFORENING'
  | 'HJO_ELNAT'
  | 'HOLAND_OG_SETSKOG_ELVERK'
  | 'INDRE_HORDALAND_KRAFTNETT'
  | 'ISALTEN_NETT'
  | 'JAMTKRAFT'
  | 'JAREN_EVERK'
  | 'KARLSBORGS_ELNAT'
  | 'KE_NETT'
  | 'KLIVE'
  | 'KUNGALV_ENERGI'
  | 'KVAM_ENERGI_NETT'
  | 'KYSTNETT'
  | 'LANDSKRONA_ENERGI'
  | 'LEDE'
  | 'LEGA_NETT'
  | 'LERUM'
  | 'LEVA'
  | 'LINDE_ENERGI'
  | 'LINEA'
  | 'LINJA'
  | 'LNETT'
  | 'LUCERNA'
  | 'LUOSTEJOK_NETT'
  | 'LYSNA'
  | 'MELLOM'
  | 'MELOY_NETT'
  | 'MIDTNETT'
  | 'MJOLBY_KRAFTNAT'
  | 'MODALEN_KRAFTLAG'
  | 'MOLNDAL_ENERGI'
  | 'MORENETT'
  | 'NATKRAFT_BORAS'
  | 'NEAS'
  | 'NETTINORD'
  | 'NETTSELSKAPET'
  | 'NJUDUNG_SAVSJO'
  | 'NJUDUNG_VETLANDA'
  | 'NORDLANDSNETT'
  | 'NORDVESTNETT'
  | 'NOREFJELL_NETT'
  | 'NORGESNETT'
  | 'NOSSEBRO_ENERGI'
  | 'ODDA_ENERGI_NETT'
  | 'OLOFSTROMS_KRAFT'
  | 'OLSERODS'
  | 'OVERTORNEA_ENERGI'
  | 'OVIK_ENERGI'
  | 'OXELO_ENERGI'
  | 'RAKKESTAD_ENERGI'
  | 'RAUMA_ENERGI'
  | 'RK_NETT'
  | 'ROLLAG_ELVERK_NETT'
  | 'ROMSDALSNETT'
  | 'ROROS_EVERK_NETT'
  | 'S_NETT'
  | 'SANDHULT_SANDAREDS_ELNAT'
  | 'SANDOY_ENERGI'
  | 'SANDVIKEN_ENERGI'
  | 'SJOGERSTADS'
  | 'SKELLEFTEA_KRAFT'
  | 'SKIAKER_NETT'
  | 'SKJAK_ENERGI_NETT'
  | 'SKURUPS_ELVERK'
  | 'SODERHAMN'
  | 'SOR_AURDAL_ENERGI_NETT'
  | 'SUNETT'
  | 'STANGE_ENERGI_NETT'
  | 'STANNUM'
  | 'STRAM'
  | 'STRAUMEN_NETT'
  | 'STRAUMNETT'
  | 'SYGNIR'
  | 'TELEMARK_NETT'
  | 'TENDRA_NETT'
  | 'TENSIO_TN'
  | 'TENSIO_TS'
  | 'TINDRA_NETT'
  | 'TRANAS_ENERGI'
  | 'TROLLFJORD_NETT'
  | 'TROLLHATTAN_ENERGI'
  | 'TROMS_KRAFT_NETT'
  | 'UDDEVALLA_ENERGI'
  | 'ULRICEHAMNS_ENERGI'
  | 'UMEA_ENERGI'
  | 'UPPLANDS_ENERGI'
  | 'UVDAL_KRAFTFORSYNING'
  | 'VAGGERYDS_ELVERK'
  | 'VANERENERGI'
  | 'VANG_ENERGI'
  | 'VARBERGSORTENS'
  | 'VASTERBERGSLAGENS_ELNAT'
  | 'VATTENFALL'
  | 'VESTALL'
  | 'VESTMAR_NETT'
  | 'VESTTELEMARK_KRAFTLAG'
  | 'VEVIG'
  | 'VISSI'
  | 'VONETT';

/** Query delivery charge models using `gln` or our `id` */
export type DeliveryChargeModelInput = {
  id?: Maybe<DeliveryChargeModelId>;
  gln?: Maybe<Scalars['Gln']>;
  date?: Maybe<Scalars['Date']>;
};

export type DeliveryChargeTaxesApplicable = {
  __typename?: 'DeliveryChargeTaxesApplicable';
  vat: Scalars['Boolean'];
  electricalPowerTax: Scalars['Boolean'];
};

export type DemandChargePeriod =
  | 'Year'
  | 'Month';

export type DemandChargeRange = {
  __typename?: 'DemandChargeRange';
  fromKw: Scalars['Int'];
  untilKw: Scalars['Int'];
};

export type DemandChargeSeason = {
  __typename?: 'DemandChargeSeason';
  months: MonthRange;
  pricePerKw: PriceSpecification;
};

export type DemandChargeSeasons = {
  __typename?: 'DemandChargeSeasons';
  summer: DemandChargeSeason;
  winter: DemandChargeSeason;
};

export type DemandChargeStep = {
  __typename?: 'DemandChargeStep';
  pricePerKw: PriceSpecification;
  rangeInKw: DemandChargeRange;
};

export type DemandChargeStepBusiness = {
  __typename?: 'DemandChargeStepBusiness';
  type: EnergyModelBusinessType;
  demandChargeSteps: Array<DemandChargeStep>;
  demandChargeAboveStepsPerKw: PriceSpecification;
  demandChargePeriod: DemandChargePeriod;
  energyPricePerKwh: PricesAndTaxesBusiness;
};

export type DemandChargeStepWithSeasons = {
  __typename?: 'DemandChargeStepWithSeasons';
  rangeInKw: DemandChargeRange;
  seasons: DemandChargeSeasons;
};

export type DemandChargeStepWithSeasonsBusiness = {
  __typename?: 'DemandChargeStepWithSeasonsBusiness';
  type: EnergyModelBusinessType;
  demandChargeStepsWithSeasons: Array<DemandChargeStepWithSeasons>;
  demandChargeAboveSteps: DemandChargeSeasons;
  demandChargePeriod: DemandChargePeriod;
  energyPricePerKwh: PricesAndTaxesBusiness;
};

export type DemandTariffCost = {
  __typename?: 'DemandTariffCost';
  cost: PriceSpecification;
};

export type DemandTariffTier = {
  __typename?: 'DemandTariffTier';
  powerRangeInKw: PowerRangeInKw;
  pricePerKw: PriceSpecification;
};

/**
 * Type departure times on charge settings.
 * - `departureTimeOverride` is the departure time override for the current day (Optional).
 * - `monday` is the departure time for Monday with format `HH:mm`.
 * - ... etc.
 */
export type DepartureTimes = {
  __typename?: 'DepartureTimes';
  monday: Scalars['String'];
  tuesday: Scalars['String'];
  wednesday: Scalars['String'];
  thursday: Scalars['String'];
  friday: Scalars['String'];
  saturday: Scalars['String'];
  sunday: Scalars['String'];
  departureTimeOverride?: Maybe<Scalars['Date']>;
};

/**
 * Input object for setting departure times on charge settings.
 * - `departureTimeOverride` is the departure time override for the current day (Optional).
 * - `monday` is the departure time for Monday with format `HH:mm`.
 * - ... etc.
 */
export type DepartureTimesInput = {
  monday: Scalars['String'];
  tuesday: Scalars['String'];
  wednesday: Scalars['String'];
  thursday: Scalars['String'];
  friday: Scalars['String'];
  saturday: Scalars['String'];
  sunday: Scalars['String'];
  departureTimeOverride?: Maybe<Scalars['Date']>;
};

export type DeviceFilter = {
  deviceIds?: Maybe<Array<Scalars['String']>>;
  recentMeterIds?: Maybe<Array<Scalars['String']>>;
};


export type DeviceIdentifierInput = {
  deviceId?: Maybe<Scalars['DeviceId']>;
  meterId?: Maybe<Scalars['MeterId']>;
};

export type DeviceInfo = {
  __typename?: 'DeviceInfo';
  activation?: Maybe<Activation>;
  deviceId: Scalars['String'];
  type: Scalars['String'];
  claimId: Scalars['String'];
  deviceName?: Maybe<Scalars['String']>;
  intermediaryId?: Maybe<Scalars['String']>;
  recentMeterId?: Maybe<Scalars['MeterId']>;
  recentMeter?: Maybe<RecentMeter>;
  shippedAt?: Maybe<Scalars['String']>;
  shippedTo?: Maybe<ShippedToUserInfo>;
  ownedBy?: Maybe<OwnedByUserInfo>;
  refurbishInfo?: Maybe<RefurbishInfo>;
  returnInfo?: Maybe<ReturnInfo>;
  claimedAt?: Maybe<Scalars['String']>;
  claimedBy?: Maybe<Scalars['String']>;
  permissions: Array<DevicePermission>;
  diagnostic?: Maybe<DiagnosticInfo>;
  preferredEnergyBiddingZone?: Maybe<EnergyBiddingZone>;
  metadata?: Maybe<DeviceMetadata>;
  subscription?: Maybe<SubscriptionBilling>;
  updateStatus?: Maybe<DeviceUpdateStatus>;
  webhookCallbackUrl?: Maybe<Scalars['String']>;
};

export type DeviceInput = {
  id: Scalars['String'];
  type: DeviceType;
  metadata: DeviceMetadataInput;
};

export type DeviceMetadata = {
  __typename?: 'DeviceMetadata';
  firmwareVersionFactoryPartition?: Maybe<Scalars['String']>;
  espIdfVersionFactoryPartition?: Maybe<Scalars['String']>;
  memoryPartitioning?: Maybe<Scalars['String']>;
  chip?: Maybe<Scalars['String']>;
  productBatch?: Maybe<Scalars['String']>;
  firmwareTheme?: Maybe<Scalars['String']>;
  iccid?: Maybe<Scalars['String']>;
  imei?: Maybe<Scalars['String']>;
  modemFirmwareVersion?: Maybe<Scalars['String']>;
};

export type DeviceMetadataInput = {
  firmwareVersionFactoryPartition: Scalars['String'];
  espIdfVersionFactoryPartition: Scalars['String'];
  memoryPartitioning: Scalars['String'];
  chip: Scalars['String'];
  productBatch: Scalars['String'];
  firmwareTheme: Scalars['String'];
  iccid?: Maybe<Scalars['String']>;
  imei?: Maybe<Scalars['String']>;
  modemFirmwareVersion?: Maybe<Scalars['String']>;
};

/**
 * Input object for updating ownership information
 *
 * Fields will be replaced and missing fields will be removed
 */
export type DeviceOwnerInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  postalAddress?: Maybe<OptionalFieldsPostalAddressInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  externalUserId?: Maybe<Scalars['String']>;
};

export type DevicePermission = {
  __typename?: 'DevicePermission';
  permittedEmail: Scalars['String'];
  permittedUserId: Scalars['String'];
};

export type DeviceQueryIdentifier = {
  deviceId?: Maybe<Scalars['String']>;
  claimId?: Maybe<Scalars['String']>;
  recentMeterId?: Maybe<Scalars['String']>;
};

export type DeviceQueryInput = {
  identifier: DeviceQueryIdentifier;
};

export type DeviceQueryPayload = {
  __typename?: 'DeviceQueryPayload';
  device: DeviceInfo;
};

export type DeviceRefurbishParts = {
  __typename?: 'DeviceRefurbishParts';
  pcbaOk: Scalars['Boolean'];
  frontCasingOk: Scalars['Boolean'];
  backCasingOk: Scalars['Boolean'];
  deviceLabelOk: Scalars['Boolean'];
  cardboardBoxOk: Scalars['Boolean'];
  manualOk: Scalars['Boolean'];
  rj45Ok: Scalars['Boolean'];
  usbOk?: Maybe<Scalars['Boolean']>;
  deviceTypeStickerOk: Scalars['Boolean'];
  boxDeviceLabelOk: Scalars['Boolean'];
  sealOk: Scalars['Boolean'];
};

export type DeviceStats = {
  __typename?: 'DeviceStats';
  total: Scalars['Int'];
  returnedDevices: Scalars['Int'];
  devicesAtCustomer: Scalars['Int'];
  devicesActivated: Scalars['Int'];
  devicesActivationReady: Scalars['Int'];
  devicesDeactivated: Scalars['Int'];
};

/** The type of device */
export type DeviceType =
  | 'HAN_WIFI'
  | 'HAN_LTEM';

export type DeviceUpdateStatus = {
  __typename?: 'DeviceUpdateStatus';
  finishedAt?: Maybe<Scalars['Date']>;
  progressPercent: Scalars['Int'];
  startedAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  version: Scalars['Int'];
};

/** Diagnostic about network state and reboots */
export type DiagnosticInfo = {
  __typename?: 'DiagnosticInfo';
  lastMessageAt?: Maybe<Scalars['Date']>;
  lastPingAt?: Maybe<Scalars['Date']>;
  signalStrength?: Maybe<Scalars['Int']>;
  reboots?: Maybe<Scalars['Int']>;
  firmwareVersion?: Maybe<Scalars['Int']>;
  publishInterval?: Maybe<Scalars['Float']>;
  hanEnergy?: Maybe<Scalars['Boolean']>;
};

/**
 * Energy model with different prices for day and night, may also have different prices for
 * weekends and holidays
 */
export type DifferentPricesDayAndNight = {
  __typename?: 'DifferentPricesDayAndNight';
  type: EnergyModelType;
  prices: DayAndNightPrices;
  weekendPricesFollowNightPrices: Scalars['Boolean'];
  holidayPricesFollowNightPrices: Scalars['Boolean'];
};

/**
 * Has different prices for day and night, may also have different prices for
 * weekends and holidays
 */
export type DifferentPricesDayAndNightBusiness = {
  __typename?: 'DifferentPricesDayAndNightBusiness';
  type: EnergyModelBusinessType;
  prices: DayAndNightPricesBusiness;
  weekendPricesFollowNightPrices: Scalars['Boolean'];
  holidayPricesFollowNightPrices: Scalars['Boolean'];
};

/** Energy model with different prices for summer and winter */
export type DifferentPricesSeasonal = {
  __typename?: 'DifferentPricesSeasonal';
  type: EnergyModelType;
  seasons: SeasonalPricesWithoutDayAndNight;
};

/** Has different prices for summer and winter */
export type DifferentPricesSeasonalBusiness = {
  __typename?: 'DifferentPricesSeasonalBusiness';
  type: EnergyModelBusinessType;
  seasons: SeasonalPricesWithoutDayAndNightBusiness;
};

/**
 * Energy model with different prices for summer and winter, day and night, may also have
 * different prices for weekends and holidays
 */
export type DifferentPricesSeasonalDayAndNight = {
  __typename?: 'DifferentPricesSeasonalDayAndNight';
  type: EnergyModelType;
  seasons: SeasonalPrices;
  weekendPricesFollowNightPrices: Scalars['Boolean'];
  holidayPricesFollowNightPrices: Scalars['Boolean'];
};

/**
 * Has different prices for summer and winter, day and night, may also have
 * different prices for weekends and holidays
 */
export type DifferentPricesSeasonalDayAndNightBusiness = {
  __typename?: 'DifferentPricesSeasonalDayAndNightBusiness';
  type: EnergyModelBusinessType;
  seasons: SeasonalPricesBusiness;
  weekendPricesFollowNightPrices: Scalars['Boolean'];
  holidayPricesFollowNightPrices: Scalars['Boolean'];
};

export type DisabledSmartChargingStatus = {
  __typename?: 'DisabledSmartChargingStatus';
  type: SmartChargingStatusType;
  externalStart: Scalars['Boolean'];
};

export type DisconnectVendorInput = {
  vendor: IntegrationVendor;
};

export type DisconnectVendorPayload = {
  __typename?: 'DisconnectVendorPayload';
  success: Scalars['Boolean'];
};

/** Return type for electrical power taxes */
export type ElectricalPowerTaxes = {
  __typename?: 'ElectricalPowerTaxes';
  electricalPowerTax: Scalars['Float'];
  vatPart: Scalars['Float'];
  validFrom: Scalars['Date'];
};

/** Query electrical power taxes */
export type ElectricalPowerTaxesInput = {
  /** Defaults to today if not specified */
  date?: Maybe<Scalars['Date']>;
  /** Defaults to 'NO' if not specified */
  countryCode?: Maybe<CountryCode>;
  /** Defaults to 'PRIVATE' if not specified */
  consumerType?: Maybe<ConsumerType>;
};

export type EmailActionInput = {
  email: Scalars['String'];
  redirectUri?: Maybe<Scalars['String']>;
};

export type EmailIdentity = IdentityBase & {
  __typename?: 'EmailIdentity';
  email: Scalars['String'];
  type: IdentityType;
};

export type EmailIdentityInput = {
  email: Scalars['String'];
};

export type EndUserAlertSettingCreator = {
  __typename?: 'EndUserAlertSettingCreator';
  type: AlertSettingCreatorType;
  userId: Scalars['ID'];
};

/** Energy bidding zones, as reported by the _European Network of Transmission System Operators for Electricity_ (ENTSO-E). */
export type EnergyBiddingZone =
  | 'UNRECOGNIZED'
  /** Norway: south east */
  | 'NO1'
  /** Norway: south */
  | 'NO2'
  /** Norway: mid */
  | 'NO3'
  /** Norway: north */
  | 'NO4'
  /** Norway: west */
  | 'NO5';

export type EnergyCost = {
  __typename?: 'EnergyCost';
  cost: PricesAndTaxes;
};

/** Return type for energy fund taxes */
export type EnergyFundTaxes = {
  __typename?: 'EnergyFundTaxes';
  yearlyEnergyFundTax: Scalars['Float'];
  energyFundTax: Scalars['Float'];
  vatPart: Scalars['Float'];
  validFrom: Scalars['Date'];
};

/** Query energy fund taxes */
export type EnergyFundTaxesInput = {
  /** Defaults to today if not specified */
  date?: Maybe<Scalars['Date']>;
  /** Defaults to 'NO' if not specified */
  countryCode?: Maybe<CountryCode>;
  /** Defaults to 'PRIVATE' if not specified */
  consumerType?: Maybe<ConsumerType>;
};

export type EnergyModelBusinessType =
  | 'ConstantPriceBusiness'
  | 'DemandChargeStepBusiness'
  | 'DemandChargeStepWithSeasonsBusiness'
  | 'DifferentPricesDayAndNightBusiness'
  | 'DifferentPricesSeasonalDayAndNightBusiness'
  | 'DifferentPricesSeasonalBusiness'
  | 'FuseBasedSelectableBusiness'
  | 'SelectableEnergyModelBusiness';

export type EnergyModelSeasonalConstant = {
  __typename?: 'EnergyModelSeasonalConstant';
  highDemandHoursForWorkDays?: Maybe<HourRange>;
  highDemand: PricesForPartOfYearWithoutDayAndNight;
  lowDemand: PricesForPartOfYearWithoutDayAndNight;
};

export type EnergyModelType =
  | 'ConstantPrice'
  | 'DifferentPricesDayAndNight'
  | 'DifferentPricesSeasonalDayAndNight'
  | 'DifferentPricesSeasonal'
  | 'FuseBasedSelectable'
  | 'SelectableEnergyModel'
  | 'HomeBasedSelectableSeasonal';

export type EnergyPriceOfHour = {
  __typename?: 'EnergyPriceOfHour';
  from?: Maybe<Scalars['Date']>;
  to?: Maybe<Scalars['Date']>;
  priceSpecification?: Maybe<PriceSpecification>;
};

/**
 * Energy range is inclusive the from and to fields. A normal range is from 0 to
 * 1999 Wh. The granularity of our data is in Wh.
 */
export type EnergyRangeInWh = {
  __typename?: 'EnergyRangeInWh';
  /** From, inclusive */
  from: Scalars['Int'];
  /** To, inclusive */
  to: Scalars['Int'];
};

export type Error = UserNotPermittedError | UserNotAuthenticatedError;

export type ErrorType =
  | 'UserNotPermittedError'
  | 'UserNotAuthenticatedError';

/** An event type describes the EstimatedHourlyActiveEnergyLimitWarningAlertSetting event. */
export type EstimatedConsumptionWarningEvent = {
  __typename?: 'EstimatedConsumptionWarningEvent';
  at: Scalars['Date'];
  userId?: Maybe<Scalars['String']>;
  intermediaryId?: Maybe<Scalars['String']>;
  alertSettingId: Scalars['String'];
  alertType: SentAlertAlertType;
  notificationChannelType: NotificationChannelType;
  deviceId: Scalars['String'];
  type: AlertEventType;
  energyUsed?: Maybe<Scalars['Float']>;
  limitInWattHours: Scalars['Float'];
};

/** An alert that continuously evaluates whether the device has crossed a set limit for energy consumption within the current hour. */
export type EstimatedHourlyActiveEnergyLimitWarningAlertSetting = {
  __typename?: 'EstimatedHourlyActiveEnergyLimitWarningAlertSetting';
  alertType: AlertType;
  createdBy: AlertSettingCreator;
  deviceId: Scalars['String'];
  id: Scalars['ID'];
  limitInWattHours: Scalars['Int'];
  notificationChannels: Array<NotificationChannel>;
};

export type ExternalUserIdentifier = {
  __typename?: 'ExternalUserIdentifier';
  externalId: Scalars['String'];
};

export type ExternalUserIdentifierInput = {
  externalId: Scalars['String'];
};

export type ExternalUserIdentity = IdentityBase & {
  __typename?: 'ExternalUserIdentity';
  externalId: Scalars['String'];
  intermediaryId: Scalars['String'];
  type: IdentityType;
};

export type FixedFeeCost = {
  __typename?: 'FixedFeeCost';
  cost: PricesAndTaxes;
};

export type FullyChargedSmartChargingStatus = {
  __typename?: 'FullyChargedSmartChargingStatus';
  type: SmartChargingStatusType;
  externalStart: Scalars['Boolean'];
};

/**
 * Capacity model were users can select whether they want a seasonal or constant price model (in combination with the energy model)
 *
 * Capacity model used in some Swedish regions.
 */
export type FuseAndHomeBasedSelectableSeasonal = {
  __typename?: 'FuseAndHomeBasedSelectableSeasonal';
  type: CapacityModelType;
  /** Prices for apartments */
  apartments: MonthlyPriceSpecification;
  /** Prices for houses */
  houses: FuseBasedSelectableSeasonal;
  /** Yearly transmission fees */
  yearlyEnergyFund: PriceSpecification;
};

/**
 * Capacity model where fuse size and type of home make the capacity steps.
 *
 * Capacity model used in some Swedish regions.
 */
export type FuseAndHomeType = {
  __typename?: 'FuseAndHomeType';
  type: CapacityModelType;
  /** Prices for apartments */
  apartments: FuseAndHomeTypePrices;
  /** Prices for houses */
  houses: FuseAndHomeTypePrices;
  /** Yearly transmission fees */
  yearlyEnergyFund: PriceSpecification;
};

export type FuseAndHomeTypePrices = {
  __typename?: 'FuseAndHomeTypePrices';
  currentRangePrices: Array<CurrentRangePrice>;
  yearlyPriceAboveCurrentRanges: PriceSpecification;
};

/**
 * Capacity model where grid voltage and/or fuse size make the capacity steps.
 *
 * Capacity model used by Alut, NettiNord, SuNett.
 */
export type FuseAndVoltageSize = {
  __typename?: 'FuseAndVoltageSize';
  type: CapacityModelType;
  /** Prices for one phase networks */
  onePhasePrices: PhasePrices;
  /** Prices for three phase networks */
  threePhasePrices: PhasePrices;
};

/**
 * Capacity model where grid voltage and/or fuse size make the capacity steps.
 *
 * Capacity model used by Alut, NettiNord, SuNett.
 */
export type FuseAndVoltageSizeBusiness = {
  __typename?: 'FuseAndVoltageSizeBusiness';
  type: CapacityModelBusinessType;
  /** Prices for one phase networks */
  onePhasePrices: PhasePrices;
  /** Prices for three phase networks */
  threePhasePrices: PhasePrices;
  yearlyEnergyFund: PriceSpecification;
};

/** Energy model that starts fuse based, but where the customers can select different models in some cases */
export type FuseBasedSelectable = {
  __typename?: 'FuseBasedSelectable';
  type: EnergyModelType;
  onePhasePrices: SelectablePhasePrices;
  threePhasePrices: SelectablePhasePrices;
};

/** A model that starts fuse based, but where the customers can select different models in some cases */
export type FuseBasedSelectableBusiness = {
  __typename?: 'FuseBasedSelectableBusiness';
  type: EnergyModelBusinessType;
  onePhasePrices: SelectablePhasePricesBusiness;
  threePhasePrices: SelectablePhasePricesBusiness;
};

export type FuseBasedSelectableSeasonal = {
  __typename?: 'FuseBasedSelectableSeasonal';
  regular: Array<CurrentRangeMonthlyPrice>;
  seasonal: Array<CurrentRangeMonthlyPrice>;
};

/** Input for the `getChargeSettings` query */
export type GetChargeSettingsInput = {
  vehicleId: Scalars['String'];
};

/** Payload for the `getChargeSettings` query */
export type GetChargeSettingsPayload = {
  __typename?: 'GetChargeSettingsPayload';
  chargeSettings: ChargeSettings;
};

export type GetJedlixChargeSettingsInput = {
  vehicleId: Scalars['String'];
};


export type GpsDisabledConfiguredIntegrationVendorVehicleLocationState = {
  __typename?: 'GpsDisabledConfiguredIntegrationVendorVehicleLocationState';
  type: ConfiguredIntegrationVendorVehicleLocationStateType;
};

export type GpsNeverActivatedConfiguredIntegrationVendorVehicleLocationState = {
  __typename?: 'GpsNeverActivatedConfiguredIntegrationVendorVehicleLocationState';
  type: ConfiguredIntegrationVendorVehicleLocationStateType;
};

export type GrantDeviceAccessInput = {
  deviceId: Scalars['String'];
  permittedIdentity: EmailIdentityInput;
};

/**
 * Energy model were users can select whether they want a seasonal or constant price model (in combination with the capacity model)
 *
 * Energy model used in some Swedish regions.
 */
export type HomeBasedSelectableSeasonal = {
  __typename?: 'HomeBasedSelectableSeasonal';
  type: EnergyModelType;
  /** Prices for apartments */
  apartments: PricesAndTaxes;
  /** Prices for houses */
  houses: SelectableSeasonal;
};

export type HourRange = {
  __typename?: 'HourRange';
  /** 0-23, including start */
  from: Scalars['Int'];
  /** 0-23, excluding end */
  until: Scalars['Int'];
};

/** An alert that is evaluated 30 minutes into the hour and checks if the device's energy consumption is about to exceed a set limit. */
export type HourlyConsumptionLimitEstimationWarningAlertSetting = {
  __typename?: 'HourlyConsumptionLimitEstimationWarningAlertSetting';
  alertType: AlertType;
  createdBy: AlertSettingCreator;
  deviceId: Scalars['String'];
  id: Scalars['ID'];
  limitInWatts: Scalars['Int'];
  notificationChannels: Array<NotificationChannel>;
};

/** An event type that is used if HourlyConsumptionLimitEstimationWarningAlertSetting was triggered. */
export type HourlyConsumptionReachedEvent = {
  __typename?: 'HourlyConsumptionReachedEvent';
  at: Scalars['Date'];
  userId?: Maybe<Scalars['String']>;
  intermediaryId?: Maybe<Scalars['String']>;
  alertSettingId: Scalars['String'];
  alertType: SentAlertAlertType;
  notificationChannelType: NotificationChannelType;
  deviceId: Scalars['String'];
  type: AlertEventType;
  activeEnergyImported: Scalars['Float'];
  limitInWatts: Scalars['Float'];
};

/** An event type that describes the HourlyConsumptionLimitEstimationWarningAlertSetting event. */
export type HourlyConsumptionWarningEvent = {
  __typename?: 'HourlyConsumptionWarningEvent';
  at: Scalars['Date'];
  userId?: Maybe<Scalars['String']>;
  intermediaryId?: Maybe<Scalars['String']>;
  alertSettingId: Scalars['String'];
  alertType: SentAlertAlertType;
  notificationChannelType: NotificationChannelType;
  deviceId: Scalars['String'];
  type: AlertEventType;
  activePowerImportAverage: Scalars['Float'];
  consumptionThisFarInTheCurrentHourInWatts: Scalars['Float'];
  limitInWatts: Scalars['Float'];
};

export type Identity = EmailIdentity | ExternalUserIdentity | IntermediaryApiKeyIdentity | InternalIdentity;

export type IdentityBase = {
  type: IdentityType;
};

export type IdentityType =
  | 'EmailIdentity'
  | 'ExternalUserIdentity'
  | 'IntermediaryApiKeyIdentity'
  | 'InternalIdentity';

export type IntegrationAuthorizationUrlInput = {
  vendor: IntegrationVendor;
};

export type IntegrationAuthorizationUrlResponse = {
  __typename?: 'IntegrationAuthorizationUrlResponse';
  authorizationUrl: Scalars['String'];
};

export type IntegrationSessionInput = {
  integration: IntegrationType;
  redirectUrl: Scalars['String'];
};

export type IntegrationSessionPayload = {
  __typename?: 'IntegrationSessionPayload';
  url: Scalars['String'];
};

export type IntegrationType =
  | 'MILL'
  | 'ZAPTEC';

export type IntegrationVendor =
  | 'AUDI'
  | 'BMW'
  | 'CUPRA'
  | 'JAGUAR'
  | 'MINI'
  | 'PORSCHE'
  | 'RENAULT'
  | 'SKODA'
  | 'SEAT'
  | 'VOLVO'
  | 'TESLA'
  | 'VOLKSWAGEN';

export type IntegrationVendorInfo = ConfiguredIntegrationVendorInfo | NotConfiguredIntegrationVendorInfo;

export type IntegrationVendorStatus =
  | 'CONFIGURED'
  | 'NOT_CONFIGURED';

export type IntermediaryAlertSettingCreator = {
  __typename?: 'IntermediaryAlertSettingCreator';
  type: AlertSettingCreatorType;
  intermediaryId: Scalars['String'];
};

export type IntermediaryAlertSettingsFilter = {
  deviceIds?: Maybe<Array<Scalars['String']>>;
};

export type IntermediaryAlertSettingsInput = {
  filter?: Maybe<IntermediaryAlertSettingsFilter>;
};

/** Intermediary api keys are authorized at the intermediate level, to add or remove users on behalf of the intermediary. */
export type IntermediaryApiKey = {
  __typename?: 'IntermediaryApiKey';
  id: Scalars['ID'];
  created: CreationInfo;
  intermediaryId: Scalars['String'];
  name: Scalars['String'];
  startOfSecret: Scalars['String'];
  secret?: Maybe<Scalars['String']>;
  /** @deprecated Use `apiKey` */
  token?: Maybe<Scalars['String']>;
  apiKey?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Date'];
};

export type IntermediaryApiKeyIdentity = IdentityBase & {
  __typename?: 'IntermediaryApiKeyIdentity';
  apiKeyHash: Scalars['String'];
  type: IdentityType;
};

export type IntermediaryApiKeyPayload = {
  __typename?: 'IntermediaryApiKeyPayload';
  intermediaryApiKey: IntermediaryApiKey;
};

export type IntermediaryApiKeys = {
  __typename?: 'IntermediaryApiKeys';
  keys: Array<IntermediaryApiKey>;
};

export type IntermediaryApiKeysResult = IntermediaryApiKeys;


/** An intermediary is the entity or organization that has support responsibility for the devices. */
export type IntermediaryInfo = {
  __typename?: 'IntermediaryInfo';
  id: Scalars['ID'];
  devices?: Maybe<Array<DeviceInfo>>;
  apiKeys?: Maybe<IntermediaryApiKeysResult>;
  permissionScopes?: Maybe<Array<Scalars['String']>>;
  deviceStats?: Maybe<DeviceStats>;
};


/** An intermediary is the entity or organization that has support responsibility for the devices. */
export type IntermediaryInfoDevicesArgs = {
  search?: Maybe<Search>;
};

export type IntermediaryUserScope =
  | 'READ_HOURLY_REPORTS'
  | 'READ_BUFFERED_MEASUREMENTS'
  | 'READ_MEASUREMENT_STREAM'
  | 'READ_DEVICE_INFO';

export type IntermediaryUserTokenPayload = {
  __typename?: 'IntermediaryUserTokenPayload';
  accessToken: Scalars['String'];
  user: UserInfo;
};

export type IntermediaryWebhookNotificationChannel = {
  __typename?: 'IntermediaryWebhookNotificationChannel';
  type: NotificationChannelType;
  callbackUrl: Scalars['String'];
  metadata?: Maybe<Scalars['JSON']>;
};

export type IntermediaryWebhookSetting = {
  __typename?: 'IntermediaryWebhookSetting';
  id: Scalars['String'];
};

export type InternalIdentity = IdentityBase & {
  __typename?: 'InternalIdentity';
  id: Scalars['String'];
  type: IdentityType;
};

export type Interval =
  | 'HOURLY'
  | 'DAILY'
  | 'MONTHLY';

export type IntervalDuration =
  | 'QUARTER_HOUR'
  | 'HOUR';


export type JedlixActiveNotificationsPayload = {
  __typename?: 'JedlixActiveNotificationsPayload';
  notifications: Array<Maybe<JedlixNotification>>;
};

export type JedlixAddress = {
  __typename?: 'JedlixAddress';
  street?: Maybe<Scalars['String']>;
  postalCode: Scalars['String'];
  houseNumber?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  country: CountryCode;
  priceArea?: Maybe<EnergyBiddingZone>;
};

export type JedlixAddressInput = {
  street?: Maybe<Scalars['String']>;
  postalCode: Scalars['String'];
  houseNumber?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  country: CountryCode;
};

export type JedlixBrand =
  | 'ABARTH'
  | 'AIWAYS'
  | 'AUDI'
  | 'BMW'
  | 'BYD'
  | 'BENTLEY'
  | 'BYTON'
  | 'CUPRA'
  | 'CADILLAC'
  | 'CHEVROLET'
  | 'CITROEN'
  | 'DACIA'
  | 'DR_AUTOMOBILE'
  | 'DS'
  | 'FIAT'
  | 'FISKER'
  | 'FORD'
  | 'GENESIS'
  | 'HONDA'
  | 'HONGQI'
  | 'HYUNDAI'
  | 'JAC'
  | 'JAGUAR'
  | 'JEEP'
  | 'KARMA'
  | 'KIA'
  | 'LEVC'
  | 'LAND_ROVER'
  | 'LEXUS'
  | 'LIGHTYEAR'
  | 'LOTUS'
  | 'LUCID'
  | 'LYNC_AND_CO'
  | 'MG'
  | 'MASERATI'
  | 'MAZDA'
  | 'MERCEDES'
  | 'MINI'
  | 'MITSUBISHI'
  | 'NIO'
  | 'NISSAN'
  | 'OPEL'
  | 'PEUGEOT'
  | 'POLESTAR'
  | 'PORSCHE'
  | 'RENAULT'
  | 'ROLLS_ROYCE'
  | 'SEAT'
  | 'SERES'
  | 'SKODA'
  | 'SMART'
  | 'SONO'
  | 'SSANG_YONG'
  | 'SUBARU'
  | 'SUZUKI'
  | 'TESLA'
  | 'TEST'
  | 'TOYOTA'
  | 'VINFAST'
  | 'VOLKSWAGEN'
  | 'VOLVO'
  | 'XPENG';

export type JedlixChargeState = {
  __typename?: 'JedlixChargeState';
  batteryLevel: Scalars['Float'];
  range: Scalars['Float'];
  chargingStatus: VehicleChargingStatus;
  chargingLocationId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Date'];
  chargePower: Scalars['Float'];
};

export type JedlixChargingHistoryInput = {
  endTimeFrom: Scalars['Date'];
  startTimeTo: Scalars['Date'];
  interval: Scalars['Int'];
  vehicleId: Scalars['String'];
};

export type JedlixChargingHistoryLocation = {
  __typename?: 'JedlixChargingHistoryLocation';
  chargingLocationId: Scalars['String'];
  country: CountryCode;
  priceArea?: Maybe<EnergyBiddingZone>;
};

export type JedlixChargingHistoryPayload = {
  __typename?: 'JedlixChargingHistoryPayload';
  chargingSessionsInInterval: Array<Maybe<ChargingSession>>;
  chargingLocations: Array<Maybe<JedlixChargingHistoryLocation>>;
};

export type JedlixChargingLocation = {
  __typename?: 'JedlixChargingLocation';
  id: Scalars['String'];
  userId: Scalars['String'];
  coordinates: LatLng;
  address: JedlixAddress;
};

export type JedlixChargingLocationInput = {
  chargingLocationId: Scalars['String'];
};

export type JedlixChargingLocationPayload = {
  __typename?: 'JedlixChargingLocationPayload';
  chargingLocation: JedlixChargingLocation;
};

export type JedlixChargingLocationsPayload = {
  __typename?: 'JedlixChargingLocationsPayload';
  chargingLocations: Array<JedlixChargingLocation>;
};

export type JedlixConnection = {
  __typename?: 'JedlixConnection';
  id: Scalars['String'];
  isFinished: Scalars['Boolean'];
  redirectUrl?: Maybe<Scalars['String']>;
  startUrl?: Maybe<Scalars['String']>;
  redirectInfo?: Maybe<JedlixConnectionRedirectInfo>;
};

export type JedlixConnectionRedirectInfo = {
  __typename?: 'JedlixConnectionRedirectInfo';
  includeRedirectUrl?: Maybe<Scalars['Boolean']>;
  includeBody?: Maybe<Scalars['Boolean']>;
  includeCookies: Array<Maybe<Scalars['String']>>;
};

export type JedlixNotification = {
  __typename?: 'JedlixNotification';
  id: Scalars['String'];
  type: MessageType;
  createdAt: Scalars['Date'];
  title: Scalars['String'];
  messageContext: Scalars['String'];
  isDismissable: Scalars['Boolean'];
  dismissedAt?: Maybe<Scalars['Date']>;
  notificationType: JedlixNotificationType;
};

export type JedlixNotificationType =
  | 'PUSH_NOTIFICATION'
  | 'EMAIL'
  | 'SMS'
  | 'BANNER';

export type JedlixUserPayload = {
  __typename?: 'JedlixUserPayload';
  id: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  locale: Scalars['String'];
  chargingLocations?: Maybe<Array<Maybe<JedlixChargingLocation>>>;
};

export type JedlixVehicle = {
  __typename?: 'JedlixVehicle';
  id: Scalars['String'];
  details?: Maybe<VehicleInfo>;
  isConnected: Scalars['Boolean'];
  isConnectable: Scalars['Boolean'];
  capabilities: VehicleCapabilities;
  chargeState?: Maybe<JedlixChargeState>;
};

export type LatLng = {
  __typename?: 'LatLng';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type LatLngInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type ListJedlixVehiclesPayload = {
  __typename?: 'ListJedlixVehiclesPayload';
  vehicles: Array<Maybe<JedlixVehicle>>;
};

export type Location = {
  __typename?: 'Location';
  id: Scalars['String'];
  name: Scalars['String'];
  controllableIds: Array<Maybe<Scalars['String']>>;
  dso?: Maybe<DeliveryChargeModelId>;
  coordinates: LatLng;
  deviceId?: Maybe<Scalars['String']>;
  hourlyEnergyConsumptionLimit?: Maybe<Scalars['Float']>;
};

export type LocationAvailableConfiguredIntegrationVendorVehicleLocationState = {
  __typename?: 'LocationAvailableConfiguredIntegrationVendorVehicleLocationState';
  type: ConfiguredIntegrationVendorVehicleLocationStateType;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  lastUpdatedAt: Scalars['String'];
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type LoginPayload = {
  __typename?: 'LoginPayload';
  session: SessionInfo;
  user: UserInfo;
};

export type Measurement = {
  __typename?: 'Measurement';
  /** Active power import, in W */
  activePowerImport?: Maybe<Scalars['Float']>;
  /** Reactive power import, in var */
  reactivePowerImport?: Maybe<Scalars['Float']>;
  /** Active energy imported, in Wh */
  activeEnergyImported?: Maybe<Scalars['Float']>;
  /** Reactive energy imported, in varh */
  reactiveEnergyImported?: Maybe<Scalars['Float']>;
  /** Active power export, in W */
  activePowerExport?: Maybe<Scalars['Float']>;
  /** Reactive power export, in var */
  reactivePowerExport?: Maybe<Scalars['Float']>;
  /** Active energy exported, in Wh */
  activeEnergyExported?: Maybe<Scalars['Float']>;
  /** Reactive energy exported, in varh */
  reactiveEnergyExported?: Maybe<Scalars['Float']>;
  /** Voltage, phase 1, in V */
  voltagePhase1?: Maybe<Scalars['Float']>;
  /** Voltage, phase 2, in V */
  voltagePhase2?: Maybe<Scalars['Float']>;
  /** Voltage, phase 3, in V */
  voltagePhase3?: Maybe<Scalars['Float']>;
  /** Current, phase 1, in A */
  currentPhase1?: Maybe<Scalars['Float']>;
  /** Current, phase 2, in A */
  currentPhase2?: Maybe<Scalars['Float']>;
  /** Current, phase 3, in A */
  currentPhase3?: Maybe<Scalars['Float']>;
  /** Time in UTC */
  time: Scalars['Date'];
};

export type MessageType =
  | 'START_SESSION'
  | 'END_SESSION'
  | 'INVALID_CREDENTIALS'
  | 'CAR_NOT_FOUND'
  | 'CAR_NOT_REACHABLE'
  | 'ZE_INTERACTIVE_TO_BE_ACTIVATED'
  | 'ZE_INTERACTIVE_EXPIRES_SOON'
  | 'READY_FOR_PAIRING'
  | 'SESSION_IMPOSSIBLE'
  | 'CHECK_CAR_ACTIVATION'
  | 'RECEIVED_NO_POWER'
  | 'GEO_LOCATION_DISABLED'
  | 'AUDI_NOT_PAIRED'
  | 'AUDI_PAIRING_NOT_COMPLETE'
  | 'INVALID_UTILITY_CONTRACT'
  | 'RECONNECT_UTILITY_CONTRACT'
  | 'INVALID_CREDENTIALS_BANNER'
  | 'MY_RENAULT_ACCESS_REVOKED'
  | 'AUDI_REMOTE_ACCESS_DISABLED'
  | 'AUDI_REMOTE_ACCESS_DISABLED_BANNER';

/** Metadata about our services */
export type Metadata = {
  __typename?: 'Metadata';
  /** Required version to run the app. The app should block the user from using an older version than this. */
  requiredAppVersion: Scalars['String'];
  /** Suggested version to run the app. The app will present the user with information about expecting errors when running older versions of the app. */
  suggestedAppVersion: Scalars['String'];
};


export type MeterProducer =
  | 'UNRECOGNIZED'
  | 'AIDON'
  | 'KAIFA'
  | 'KAMSTRUP'
  | 'EMBRIQ';

export type MillSignInInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type MillSignInPayload = {
  __typename?: 'MillSignInPayload';
  success: Scalars['Boolean'];
};

export type Month =
  | 'JANUARY'
  | 'FEBRUARY'
  | 'MARCH'
  | 'APRIL'
  | 'MAY'
  | 'JUNE'
  | 'JULY'
  | 'AUGUST'
  | 'SEPTEMBER'
  | 'OCTOBER'
  | 'NOVEMBER'
  | 'DECEMBER';

export type MonthRange = {
  __typename?: 'MonthRange';
  /** 1-12, including start */
  from: Scalars['Int'];
  /** 1-12, exluding end */
  until: Scalars['Int'];
};

export type MonthWeights = {
  __typename?: 'MonthWeights';
  january: Scalars['Float'];
  february: Scalars['Float'];
  march: Scalars['Float'];
  april: Scalars['Float'];
  may: Scalars['Float'];
  june: Scalars['Float'];
  july: Scalars['Float'];
  august: Scalars['Float'];
  september: Scalars['Float'];
  october: Scalars['Float'];
  november: Scalars['Float'];
  december: Scalars['Float'];
};

/**
 * Capacity model where (e.g. 3) daily maximas for a month is averaged and used to
 * determine the capacity step. The steps has a range in Wh with a price, and
 * there is also a field defining the price for when all capacity steps are
 * exceeded.
 */
export type MonthlyPeaksAtDifferentDays = {
  __typename?: 'MonthlyPeaksAtDifferentDays';
  type: CapacityModelType;
  peaksPerMonth: Scalars['Int'];
  capacitySteps: Array<CapacityStep>;
  priceAboveCapacitySteps: PriceSpecification;
};

/**
 * Capacity model where (e.g. 3) daily maximas for a month is averaged and used to
 * determine the capacity step. The steps has a range in Wh with a price, and
 * there is also a field defining the price for when all capacity steps are
 * exceeded.
 *
 * Includes a yearly energy fund fee.
 */
export type MonthlyPeaksAtDifferentDaysBusiness = {
  __typename?: 'MonthlyPeaksAtDifferentDaysBusiness';
  type: CapacityModelBusinessType;
  peaksPerMonth: Scalars['Int'];
  capacitySteps: Array<CapacityStep>;
  priceAboveCapacitySteps: PriceSpecification;
  yearlyEnergyFund: PriceSpecification;
};

/**
 * Capacity model where (e.g. 3) daily maximas for a month is averaged and
 * multiplied by `pricePerKwhForAverageOfPeaks` and added to `baseFeePerYear`.
 *
 * Capacity model used by SkiakerNett, but with only one maxima.
 *
 * <https://skiakernett.no/nettleige/>
 */
export type MonthlyPeaksAtDifferentDaysLinearWithBase = {
  __typename?: 'MonthlyPeaksAtDifferentDaysLinearWithBase';
  type: CapacityModelType;
  baseFeePerYear: PriceSpecification;
  /** The number of daily peaks that will be averaged to find the capacity step. */
  peaksPerMonth: Scalars['Int'];
  pricePerKwhForAverageOfPeaks: PriceSpecification;
};

export type MonthlyPriceSpecification = {
  __typename?: 'MonthlyPriceSpecification';
  monthlyPrice: PriceSpecification;
};

export type Mutation = {
  __typename?: 'Mutation';
  addBrightNotificationChannelToAlertSetting: AddBrightNotificationChanneltToAlertSettingPayload;
  addChargingLocation: AddChargingLocationPayload;
  addChargingPermission?: Maybe<AddChargingPermissionPayload>;
  addControllableForLocation: AddControllableForLocationPayload;
  /** Add device permissions to a user. */
  addDevicePermissions?: Maybe<AddDevicePermissionsPayload>;
  addDeviceToken?: Maybe<Scalars['Void']>;
  /** Add a dynamic alert that continuously evaluates whether the device has crossed a set limit for energy consumption within the hour. */
  addEstimatedHourlyActiveEnergyLimitWarningAlertSetting: EstimatedHourlyActiveEnergyLimitWarningAlertSetting;
  /** Add an alert that is evaluated 30 minutes into the hour and checks if the device's energy consumption is about to exceed a set limit. */
  addHourlyConsumptionLimitEstimationWarningAlertSetting: HourlyConsumptionLimitEstimationWarningAlertSetting;
  addIntermediaryApiKey: IntermediaryApiKeyPayload;
  addIntermediaryWebhookNotificationChannelToAlertSetting: AddIntermediaryWebhookNotificationChannelToAlertSettingPayload;
  /** Add Location */
  addLocation: AddLocationPayload;
  addPriceWarningAlertSetting: PriceWarningAlertSetting;
  addPushNotificationChannelToAlertSetting: AddPushNotificationChannelToAlertSettingPayload;
  /** Create a user using an external user identifier of your choice. */
  addUser: AddUserPayload;
  /** Create an access token for a user. */
  addUserSession: AddUserSessionPayload;
  /** Cancel an Order */
  cancelOrder: Order;
  claimDevice: DeviceInfo;
  commonSignIn: CommonSignInPayload;
  /** @deprecated Deprecated. Please use ordersForUser, addUser, addDevicePermissions, and addUserSession individually instead. */
  connectExternalUser: ConnectExternalUserPayload;
  connectJedlixVehicle: JedlixConnection;
  /** Create charge settings for a user's vehicle. */
  createChargeSettings: CreateChargeSettingsPayload;
  createDevice: DeviceInfo;
  createJedlixChargingLocation: JedlixChargingLocation;
  createJedlixUser: JedlixUserPayload;
  deleteAlertSetting: DeleteAlertSettingPayload;
  deleteChargingLocation: DeleteChargingLocationPayload;
  deleteDeviceName?: Maybe<DeviceInfo>;
  deleteJedlixChargingLocation?: Maybe<DeleteJedlixChargingLocationPayload>;
  deleteJedlixUser?: Maybe<DeleteJedlixUserPayload>;
  deleteJedlixVehicle?: Maybe<DeleteJedlixVehiclePayload>;
  deleteMe: DeleteMePayload;
  disconnectVendor: DisconnectVendorPayload;
  grantDeviceAccess: Permission;
  login: LoginPayload;
  millSignIn?: Maybe<MillSignInPayload>;
  /** Order a device */
  orderDevice: OrderDevicePayload;
  processOrderForIntermediary?: Maybe<ProcessOrderForIntermediaryPayload>;
  /**
   * Refurbish a device.
   *
   * This mutation is only available for internal use. It is used to update the state of a device that has been refurbished.
   */
  refurbishDevice: RefurbishDevicePayload;
  register?: Maybe<Scalars['Boolean']>;
  removeChargingPermission?: Maybe<RemoveChargingPermissionPayload>;
  removeControllableFromLocation: RemoveControllableFromLocationPayload;
  /** Remove device permissions from a user. */
  removeDevicePermissions?: Maybe<RemoveDevicePermissionsPayload>;
  removeDeviceToken?: Maybe<Scalars['Void']>;
  removeIntermediaryApiKey: IntermediaryApiKeyPayload;
  /** Remove Location */
  removeLocation: RemoveLocationPayload;
  removeNotificationChannelFromAlertSetting: RemoveNotificationChannelFromAlertSettingPayload;
  /** Remove a user from the system. */
  removeUser: RemoveUserPayload;
  returnDevice: ReturnDevicePayload;
  revokeDeviceAccess?: Maybe<Scalars['Boolean']>;
  sendEmailVerificationEmail?: Maybe<Scalars['Boolean']>;
  sendResetPasswordEmail?: Maybe<Scalars['Boolean']>;
  setCoordinatesForLocation: SetCoordinatesForLocationPayload;
  setDeviceActivationStatus: SetDeviceActivationStatusPayload;
  setDeviceForLocation: SetDeviceForLocationPayload;
  setEnergyConsumptionLimitForLocation: SetEnergyConsumptionLimitForLocationPayload;
  setIntermediaryWebhookSetting: SetIntermediaryWebhookSettingPayload;
  setNameForLocation: SetNameForLocationPayload;
  shipDevice: DeviceInfo;
  shipOrder: Order;
  startIntegrationSession: IntegrationSessionPayload;
  startOperationalCheck: StartOperationalCheckPayload;
  testWebhookSetting: TestWebhookSettingPayload;
  /** Update an existing user's vehicle charge settings. */
  updateChargeSettings: UpdateChargeSettingsPayload;
  updateChargingLocation: UpdateChargingLocationPayload;
  /**
   * Update device metadata info. When running jig config/setup/calibration a second time, this endpoint should be used.
   * The input and output is similar to createDevice.
   */
  updateDeviceMetadata?: Maybe<DeviceInfo>;
  /**
   * Updates the device name
   *
   * Since the `deviceName` input is mandatory, there is another mutation to delete the device name.
   */
  updateDeviceName?: Maybe<DeviceInfo>;
  /**
   * Update device owner information. Will not change the info about who the device was shipped to.
   *
   * `deviceOwner` will be replaced by the new input object, and missing fields will be removed.
   */
  updateDeviceOwner?: Maybe<DeviceInfo>;
  /** Update the limit for when an alert should be triggered for the dynamic alert. */
  updateEstimatedHourlyActiveEnergyLimitWarningAlertSetting: UpdateEstimatedHourlyActiveEnergyLimitWarningAlertSettingPayload;
  /** Update the limit for when an alert should be triggered for the half-hour alert. */
  updateHourlyConsumptionLimitEstimationWarningAlertSetting: UpdateHourlyConsumptionLimitEstimationWarningAlertSettingPayload;
  updateJedlixChargingLocation: JedlixChargingLocation;
  updatePreferredEnergyBiddingZone?: Maybe<Scalars['Boolean']>;
  updateSmartChargingPolicy: UpdateSmartChargingPolicyPayload;
  zaptecClaimInstallation: ZaptecClaimInstallationPayload;
};


export type MutationAddBrightNotificationChannelToAlertSettingArgs = {
  input: AddBrightNotificationChannelToAlertSettingInput;
};


export type MutationAddChargingLocationArgs = {
  input: AddChargingLocationInput;
};


export type MutationAddChargingPermissionArgs = {
  input: AddChargingPermissionInput;
};


export type MutationAddControllableForLocationArgs = {
  input: AddControllableForLocationInput;
};


export type MutationAddDevicePermissionsArgs = {
  input: AddDevicePermissionsInput;
};


export type MutationAddDeviceTokenArgs = {
  deviceToken: Scalars['String'];
};


export type MutationAddEstimatedHourlyActiveEnergyLimitWarningAlertSettingArgs = {
  input: AddEstimatedHourlyActiveEnergyLimitWarningAlertSettingInput;
};


export type MutationAddHourlyConsumptionLimitEstimationWarningAlertSettingArgs = {
  input: AddHourlyConsumptionLimitEstimationWarningAlertSettingInput;
};


export type MutationAddIntermediaryApiKeyArgs = {
  input: AddIntermediaryApiKeyInput;
};


export type MutationAddIntermediaryWebhookNotificationChannelToAlertSettingArgs = {
  input: AddIntermediaryWebhookNotificationChannelToAlertSettingInput;
};


export type MutationAddLocationArgs = {
  input: AddLocationInput;
};


export type MutationAddPriceWarningAlertSettingArgs = {
  input: AddPriceWarningAlertSettingInput;
};


export type MutationAddPushNotificationChannelToAlertSettingArgs = {
  input: AddPushNotificationChannelToAlertSettingInput;
};


export type MutationAddUserArgs = {
  input: AddUserInput;
};


export type MutationAddUserSessionArgs = {
  input: AddUserSessionInput;
};


export type MutationCancelOrderArgs = {
  input: CancelOrderInput;
};


export type MutationClaimDeviceArgs = {
  claimId: Scalars['ClaimId'];
};


export type MutationCommonSignInArgs = {
  input: CommonSignInInput;
};


export type MutationConnectExternalUserArgs = {
  input: ConnectExternalUserInput;
};


export type MutationConnectJedlixVehicleArgs = {
  input?: Maybe<Scalars['Void']>;
};


export type MutationCreateChargeSettingsArgs = {
  input: CreateChargeSettingsInput;
};


export type MutationCreateDeviceArgs = {
  input: DeviceInput;
};


export type MutationCreateJedlixChargingLocationArgs = {
  input: CreateJedlixChargingLocationInput;
};


export type MutationCreateJedlixUserArgs = {
  input: CreateJedlixUserInput;
};


export type MutationDeleteAlertSettingArgs = {
  input: DeleteAlertSettingInput;
};


export type MutationDeleteChargingLocationArgs = {
  input: DeleteChargingLocationInput;
};


export type MutationDeleteDeviceNameArgs = {
  deviceId: Scalars['String'];
};


export type MutationDeleteJedlixChargingLocationArgs = {
  input: DeleteJedlixChargingLocationInput;
};


export type MutationDeleteJedlixUserArgs = {
  input: Scalars['Void'];
};


export type MutationDeleteJedlixVehicleArgs = {
  input: DeleteJedlixVehicleInput;
};


export type MutationDeleteMeArgs = {
  input: DeleteMeInput;
};


export type MutationDisconnectVendorArgs = {
  input: DisconnectVendorInput;
};


export type MutationGrantDeviceAccessArgs = {
  input: GrantDeviceAccessInput;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationMillSignInArgs = {
  input: MillSignInInput;
};


export type MutationOrderDeviceArgs = {
  input: OrderDeviceInput;
};


export type MutationProcessOrderForIntermediaryArgs = {
  input: ProcessOrderForIntermediaryInput;
};


export type MutationRefurbishDeviceArgs = {
  input: RefurbishDeviceInput;
};


export type MutationRegisterArgs = {
  input: RegisterInput;
};


export type MutationRemoveChargingPermissionArgs = {
  input: RemoveChargingPermissionInput;
};


export type MutationRemoveControllableFromLocationArgs = {
  input: RemoveControllableFromLocationInput;
};


export type MutationRemoveDevicePermissionsArgs = {
  input: RemoveDevicePermissionsInput;
};


export type MutationRemoveDeviceTokenArgs = {
  deviceToken: Scalars['String'];
};


export type MutationRemoveIntermediaryApiKeyArgs = {
  input: RemoveIntermediaryApiKeyInput;
};


export type MutationRemoveLocationArgs = {
  input: RemoveLocationInput;
};


export type MutationRemoveNotificationChannelFromAlertSettingArgs = {
  input: RemoveNotificationChannelFromAlertSettingInput;
};


export type MutationRemoveUserArgs = {
  input: RemoveUserInput;
};


export type MutationReturnDeviceArgs = {
  input: ReturnDeviceInput;
};


export type MutationRevokeDeviceAccessArgs = {
  input: RevokeDeviceAccessInput;
};


export type MutationSendEmailVerificationEmailArgs = {
  input: EmailActionInput;
};


export type MutationSendResetPasswordEmailArgs = {
  input: EmailActionInput;
};


export type MutationSetCoordinatesForLocationArgs = {
  input: SetCoordinatesForLocationInput;
};


export type MutationSetDeviceActivationStatusArgs = {
  input: SetDeviceActivationStatusInput;
};


export type MutationSetDeviceForLocationArgs = {
  input: SetDeviceForLocationInput;
};


export type MutationSetEnergyConsumptionLimitForLocationArgs = {
  input: SetEnergyConsumptionLimitForLocationInput;
};


export type MutationSetIntermediaryWebhookSettingArgs = {
  input: SetIntermediaryWebhookSettingInput;
};


export type MutationSetNameForLocationArgs = {
  input: SetNameForLocationInput;
};


export type MutationShipDeviceArgs = {
  deviceIdentifier: ShipDeviceIdentifierInput;
  intermediaryId: Scalars['String'];
  shipTo?: Maybe<ShipToUserInput>;
  subscription: SubscriptionInput;
  webhookCallbackUrl?: Maybe<Scalars['String']>;
};


export type MutationShipOrderArgs = {
  input: ShipOrderInput;
};


export type MutationStartIntegrationSessionArgs = {
  input: IntegrationSessionInput;
};


export type MutationStartOperationalCheckArgs = {
  input: StartOperationalCheckInput;
};


export type MutationTestWebhookSettingArgs = {
  input: TestWebhookSettingInput;
};


export type MutationUpdateChargeSettingsArgs = {
  input: UpdateChargeSettingsInput;
};


export type MutationUpdateChargingLocationArgs = {
  input: UpdateChargingLocationInput;
};


export type MutationUpdateDeviceMetadataArgs = {
  input: DeviceInput;
};


export type MutationUpdateDeviceNameArgs = {
  deviceId: Scalars['String'];
  deviceName: Scalars['String'];
};


export type MutationUpdateDeviceOwnerArgs = {
  deviceId: Scalars['String'];
  deviceOwner: DeviceOwnerInput;
};


export type MutationUpdateEstimatedHourlyActiveEnergyLimitWarningAlertSettingArgs = {
  input: UpdateEstimatedHourlyActiveEnergyLimitWarningAlertSettingInput;
};


export type MutationUpdateHourlyConsumptionLimitEstimationWarningAlertSettingArgs = {
  input: UpdateHourlyConsumptionLimitEstimationWarningAlertSettingInput;
};


export type MutationUpdateJedlixChargingLocationArgs = {
  input: UpdateJedlixChargingLocationInput;
};


export type MutationUpdatePreferredEnergyBiddingZoneArgs = {
  deviceId: Scalars['String'];
  preferredEnergyBiddingZone: EnergyBiddingZone;
};


export type MutationUpdateSmartChargingPolicyArgs = {
  input: UpdateSmartChargingPolicyInput;
};


export type MutationZaptecClaimInstallationArgs = {
  input: ZaptecClaimInstallationInput;
};

export type MyDevicesInput = {
  filter?: Maybe<DeviceFilter>;
};

export type NotConfiguredIntegrationVendorInfo = {
  __typename?: 'NotConfiguredIntegrationVendorInfo';
  status: IntegrationVendorStatus;
  vendor: IntegrationVendor;
};

export type NotificationChannel = IntermediaryWebhookNotificationChannel | PushNotificationChannel | BrightNotificationChannel;

export type NotificationChannelType =
  | 'BRIGHT_NOTIFICATION'
  | 'PUSH_NOTIFICATION'
  | 'INTERMEDIARY_WEBHOOK';

export type NotificationType =
  | 'PRICE_WARNING';

export type OptionalFieldsPostalAddress = {
  __typename?: 'OptionalFieldsPostalAddress';
  streetAddress?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  country?: Maybe<PostcodeCountry>;
};

export type OptionalFieldsPostalAddressInput = {
  streetAddress?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  country?: Maybe<PostcodeCountry>;
};

export type Order = {
  __typename?: 'Order';
  intermediaryId: Scalars['String'];
  orderId: Scalars['String'];
  orderStatus: OrderStatus;
  recipient: PostalRecipient;
  subscriptionType: OrderSubscriptionType;
  type: DeviceType;
  updatedAt: Scalars['Date'];
  deviceId?: Maybe<Scalars['String']>;
  trackingLink?: Maybe<Scalars['String']>;
  webhookCallbackUrl?: Maybe<Scalars['String']>;
};

export type OrderDeviceInput = {
  /** The person to whom the device should be shipped */
  recipient: PostalRecipientInput;
  subscriptionType: OrderSubscriptionType;
  type: DeviceType;
  /** The URL to which the webhook should be sent when the order status is changed */
  webhookCallbackUrl?: Maybe<Scalars['String']>;
};

export type OrderDevicePayload = {
  __typename?: 'OrderDevicePayload';
  order: Order;
  ordersForUser: Array<Maybe<Order>>;
};

export type OrderInput = {
  orderId: Scalars['String'];
};

export type OrderStatus =
  | 'ORDERED'
  | 'SHIPPED'
  | 'CANCELLED'
  | 'IN_PROGRESS';

export type OrderSubscriptionType =
  | 'PERSONAL'
  | 'BUSINESS';

export type OrderSummary = {
  __typename?: 'OrderSummary';
  intermediaryId: Scalars['String'];
  ltemsToBeShipped: Scalars['Int'];
  wifisToBeShipped: Scalars['Int'];
};

export type OrderSummaryPayload = {
  __typename?: 'OrderSummaryPayload';
  orderSummary: Array<Maybe<OrderSummary>>;
};

export type OrdersForUserInput = {
  externalUserId: Scalars['String'];
};

export type OrdersForUserPayload = {
  __typename?: 'OrdersForUserPayload';
  orders: Array<Order>;
};

/** User information for device owners */
export type OwnedByUserInfo = {
  __typename?: 'OwnedByUserInfo';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  postalAddress?: Maybe<OptionalFieldsPostalAddress>;
  phoneNumber?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  externalUserId?: Maybe<Scalars['String']>;
};

/** The fixed part is given as price per month */
export type PerMonthFixedFee = {
  __typename?: 'PerMonthFixedFee';
  pricePerMonth: PriceSpecification;
  yearlyEnergyFund: PriceSpecification;
};

/**
 * The fixed part divides a yearly price by days of the year and multiplies this
 * by days of the month
 */
export type PerYearDividedByDaysFixedFee = {
  __typename?: 'PerYearDividedByDaysFixedFee';
  pricePerYear: PriceSpecification;
  yearlyEnergyFund: PriceSpecification;
};

/** Our permission system works across different types of resources and different types of actors */
export type Permission = {
  __typename?: 'Permission';
  /** The id of the resource the permission is targeting, e.g. a deviceId */
  resourceId: Scalars['String'];
  /** The id of the entity being permitted, e.g. a userId */
  entityId: Scalars['String'];
  /** Permissions to resources, like read or write, are defined by scopes */
  scopes: Array<Scalars['String']>;
};

export type PhasePrices = {
  __typename?: 'PhasePrices';
  /** Prices for 230V networks */
  voltage230: PhaseVoltagePrices;
  /** Prices for 400V networks */
  voltage400: PhaseVoltagePrices;
};

export type PhaseVoltagePrices = {
  __typename?: 'PhaseVoltagePrices';
  currentRangePrices: Array<CurrentRangePrice>;
  yearlyPriceAboveCurrentRanges: PriceSpecification;
};

export type PlannedSmartChargingStatus = {
  __typename?: 'PlannedSmartChargingStatus';
  type: SmartChargingStatusType;
  plan: SmartChargingPlan;
  planningStatus: SmartChargingPlanningStatusType;
  externalStart: Scalars['Boolean'];
};

export type PostalAddress = {
  __typename?: 'PostalAddress';
  streetAddress: Scalars['String'];
  postcode: Scalars['String'];
  country: PostcodeCountry;
};

export type PostalAddressInput = {
  streetAddress: Scalars['String'];
  postcode: Scalars['String'];
  country: PostcodeCountry;
};

export type PostalRecipient = {
  __typename?: 'PostalRecipient';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  externalUserId: Scalars['String'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
  postalAddress: PostalRecipientPostalAddress;
};

export type PostalRecipientInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  externalUserId: Scalars['String'];
  /** Max-length 60 characters */
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
  postalAddress: PostalRecipientPostalAddressInput;
};

export type PostalRecipientPostalAddress = {
  __typename?: 'PostalRecipientPostalAddress';
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  postalCode: Scalars['String'];
  country: CountryCode;
};

export type PostalRecipientPostalAddressInput = {
  /** Max-length 35 characters */
  addressLine1: Scalars['String'];
  /** Max-length 35 characters */
  addressLine2?: Maybe<Scalars['String']>;
  /** Max-length 35 characters */
  city: Scalars['String'];
  /** 4 digits if country is Norway */
  postalCode: Scalars['String'];
  country: CountryCode;
};

export type PostcodeCountry =
  | 'NORWAY';

export type PowerRangeInKw = {
  __typename?: 'PowerRangeInKw';
  /** From, inclusive */
  from: Scalars['Int'];
  /** Until, exclusive */
  until: Scalars['Int'];
};

export type PriceSpecification = {
  __typename?: 'PriceSpecification';
  /** The actual price, this should be including VAT (MVA) where it applies */
  price: Scalars['Float'];
  /** The currency of the given price, usually in NOK */
  priceCurrency: Currency;
  /** VAT, or "Merverdiavgift" */
  vat: Scalars['Float'];
  /** The prices without VAT (MVA) */
  priceExcludingVat: Scalars['Float'];
};

export type PriceWarningAlertSetting = {
  __typename?: 'PriceWarningAlertSetting';
  alertType: AlertType;
  energyBiddingZone: EnergyBiddingZone;
  createdBy: AlertSettingCreator;
  id: Scalars['ID'];
  notificationChannels: Array<NotificationChannel>;
};

/** An event type that is used if PriceWarningAlertSetting was triggered. */
export type PriceWarningEvent = {
  __typename?: 'PriceWarningEvent';
  at: Scalars['Date'];
  userId?: Maybe<Scalars['String']>;
  intermediaryId?: Maybe<Scalars['String']>;
  alertSettingId: Scalars['String'];
  alertType: SentAlertAlertType;
  notificationChannelType: NotificationChannelType;
  energyBiddingZone: EnergyBiddingZone;
  type: AlertEventType;
};

export type PriceWarningPushNotificationSetting = {
  __typename?: 'PriceWarningPushNotificationSetting';
  notificationType: NotificationType;
  wantsNotification: Scalars['Boolean'];
  deviceId: Scalars['String'];
};

export type PriceZone = {
  __typename?: 'PriceZone';
  zone: EnergyBiddingZone;
};

export type PriceZoneInput = {
  addressInput?: Maybe<AddressInput>;
  latLngInput?: Maybe<LatLngInput>;
};

/** Prices and taxes type */
export type PricesAndTaxes = {
  __typename?: 'PricesAndTaxes';
  priceIncludingEnergyTaxes: PriceSpecification;
  priceExcludingEnergyTaxes: PriceSpecification;
  taxes: DeliveryChargeEnergyTaxes;
};

/** Prices and taxes for business */
export type PricesAndTaxesBusiness = {
  __typename?: 'PricesAndTaxesBusiness';
  priceIncludingEnergyTaxes: PriceSpecification;
  priceExcludingEnergyTaxes: PriceSpecification;
  taxes: DeliveryChargeEnergyTaxesBusiness;
};

/**
 * Price and tax info for part of a day, includes a specification of which hours
 * the price is for.
 */
export type PricesAndTaxesForPartOfDay = {
  __typename?: 'PricesAndTaxesForPartOfDay';
  hours: HourRange;
  pricesAndTaxes: PricesAndTaxes;
};

/**
 * Price and tax info for part of a day, includes a specification of which hours
 * the price is for.
 */
export type PricesAndTaxesForPartOfDayBusiness = {
  __typename?: 'PricesAndTaxesForPartOfDayBusiness';
  hours: HourRange;
  pricesAndTaxes: PricesAndTaxesBusiness;
};

/**
 * The prices for a part of year, will include the price specifiations and a
 * for what months the prices is for
 */
export type PricesForPartOfYear = {
  __typename?: 'PricesForPartOfYear';
  months: MonthRange;
  prices: DayAndNightPrices;
};

/**
 * The prices for a part of year, will include the price specifiations and a
 * for what months the prices is for
 */
export type PricesForPartOfYearBusiness = {
  __typename?: 'PricesForPartOfYearBusiness';
  months: MonthRange;
  prices: DayAndNightPricesBusiness;
};

/**
 * The prices for a part of year without differentiating day and night, will include the price specifications and a
 * for what months the prices is for
 */
export type PricesForPartOfYearWithoutDayAndNight = {
  __typename?: 'PricesForPartOfYearWithoutDayAndNight';
  months: MonthRange;
  /** @deprecated The field is not complete, use the adjacent field `pricesAndTaxes` */
  price: PriceSpecification;
  pricesAndTaxes: PricesAndTaxes;
};

/**
 * The prices for a part of year without differentiating day and night, will include the price specifiations and a
 * for what months the prices is for
 */
export type PricesForPartOfYearWithoutDayAndNightBusiness = {
  __typename?: 'PricesForPartOfYearWithoutDayAndNightBusiness';
  months: MonthRange;
  pricesAndTaxes: PricesAndTaxesBusiness;
};

export type ProcessOrderForIntermediaryInput = {
  intermediaryId: Scalars['String'];
  deviceType: DeviceType;
};

export type ProcessOrderForIntermediaryPayload = {
  __typename?: 'ProcessOrderForIntermediaryPayload';
  order?: Maybe<Order>;
};

export type PushNotificationChannel = {
  __typename?: 'PushNotificationChannel';
  type: NotificationChannelType;
  forUserWithId: Scalars['String'];
};

export type PushNotificationSetting = PriceWarningPushNotificationSetting;

export type Query = {
  __typename?: 'Query';
  /** Gets the alert events for the last 30 days. */
  alertEvents: AlertEventsPayload;
  alertEventsOfChannelType: AlertEventsOfChannelTypePayload;
  bigQueryMeasurements: Array<Measurement>;
  chargingStatistics: ChargingStatisticsPayload;
  checkJedlixConnection: JedlixConnection;
  currentIntermediary: CurrentIntermediaryPayload;
  /**
   * Day ahead prices
   *
   * Get price data from the Entso-E transparency platrom for the time range
   * specified.
   *
   * Prices are converted to NOK and VAT is added for the zones where
   * this is applicable.
   *
   * Read more about the transparency platform of the European Network of
   * Transmission System Operators at https://transparency.entsoe.eu/
   */
  dayAheadPrices: Array<Maybe<EnergyPriceOfHour>>;
  deliveryChargeArea?: Maybe<DeliveryChargeArea>;
  deliveryChargeCost: DeliveryChargeCost;
  deliveryChargeCostBusiness: DeliveryChargeCostBusiness;
  deliveryChargeCostEnterprise: DeliveryChargeCostEnterprise;
  /** Get the delivery charge model for a DSO in the private segment */
  deliveryChargeModel: DeliveryChargeModel;
  /** Get the delivery charge model for a DSO in the business segment */
  deliveryChargeModelBusiness: DeliveryChargeModelBusiness;
  /** Get the delivery charge model for a DSO in the enterprise/industry segment */
  deliveryChargeModelEnterprise: DeliveryChargeModelEnterprise;
  device: DeviceQueryPayload;
  electricalPowerTaxes: ElectricalPowerTaxes;
  energyFundTaxes: EnergyFundTaxes;
  /** Get charge settings for a Jedlix user's vehicle. */
  getChargeSettings: GetChargeSettingsPayload;
  /** Generates an authorization URL which can be embedded in a webview which in turn will allow an end user to grant our API access to control their vehicle/charger */
  integrationAuthorizationUrl: IntegrationAuthorizationUrlResponse;
  jedlixActiveNotifications: JedlixActiveNotificationsPayload;
  jedlixChargingHistory: JedlixChargingHistoryPayload;
  jedlixChargingLocation: JedlixChargingLocationPayload;
  jedlixChargingLocations: JedlixChargingLocationsPayload;
  jedlixUser: JedlixUserPayload;
  listControllablesForUser: ControllablesForUserPayload;
  listJedlixVehicles: ListJedlixVehiclesPayload;
  /** Get Locations */
  locations: Array<Location>;
  me: UserInfo;
  /**
   * Get data from the latest measurements using deviceId or meterId (or both)
   *
   * * If only deviceId is given, reports for the most recent meterId that the device was connected to in the requested range will be returned
   * * If only meterId is given, reports for this meter will be returned from the most recent deviceId that was connected to this meter in the requested range
   * * If both meterId and deviceId is given, reports will only be returned if the given deviceId was connected to the given meterId in the requested range
   *
   * In all cases the accessToken needs permissions to read reports for the corresponding deviceId.
   *
   * Measurements are only available for two to three hours.
   */
  measurements: Array<Measurement>;
  /** Get metadata about services, like minimum required app requiredAppVersion */
  metadata: Metadata;
  myDevices: Array<DeviceInfo>;
  /** Get an Order by its Id */
  order: Order;
  orderSummary: OrderSummaryPayload;
  /** Get all Orders for a given user */
  ordersForUser: OrdersForUserPayload;
  /** Get permissions containing requested scopes for the authenticated user */
  permissions: Array<Permission>;
  priceZone?: Maybe<PriceZone>;
  /**
   * Get hourly or daily reports using deviceId or meterId (or both)
   *
   * * If only deviceId is given, reports for the most recent meterId that the device was connected to in the requested range will be returned
   * * If only meterId is given, reports for this meter will be returned from the most recent deviceId that was connected to this meter in the requested range
   * * If both meterId and deviceId is given, reports will only be returned if the given deviceId was connected to the given meterId in the requested range
   *
   * In all cases the accessToken needs permissions to read reports for the corresponding deviceId.
   */
  reports: Array<Report>;
  user: UserPayload;
  vehicleSmartChargingStatus: VehicleSmartChargingStatusPayload;
};


export type QueryAlertEventsOfChannelTypeArgs = {
  input: AlertEventsOfChannelTypeInput;
};


export type QueryBigQueryMeasurementsArgs = {
  deviceId?: Maybe<Scalars['String']>;
  meterId?: Maybe<Scalars['String']>;
  from: Scalars['String'];
  to: Scalars['String'];
};


export type QueryChargingStatisticsArgs = {
  input: ChargingStatisticsInput;
};


export type QueryCheckJedlixConnectionArgs = {
  input: CheckJedlixConnectionInput;
};


export type QueryDayAheadPricesArgs = {
  from: Scalars['Date'];
  to: Scalars['Date'];
  zone: EnergyBiddingZone;
};


export type QueryDeliveryChargeAreaArgs = {
  input: DeliveryChargeAreaInput;
};


export type QueryDeliveryChargeCostArgs = {
  input: DeliveryChargeCostInput;
};


export type QueryDeliveryChargeCostBusinessArgs = {
  input: DeliveryChargeCostBusinessInput;
};


export type QueryDeliveryChargeCostEnterpriseArgs = {
  input: DeliveryChargeCostEnterpriseInput;
};


export type QueryDeliveryChargeModelArgs = {
  input: DeliveryChargeModelInput;
};


export type QueryDeliveryChargeModelBusinessArgs = {
  input: DeliveryChargeModelBusinessInput;
};


export type QueryDeliveryChargeModelEnterpriseArgs = {
  input: DeliveryChargeModelInput;
};


export type QueryDeviceArgs = {
  input: DeviceQueryInput;
};


export type QueryElectricalPowerTaxesArgs = {
  input: ElectricalPowerTaxesInput;
};


export type QueryEnergyFundTaxesArgs = {
  input: EnergyFundTaxesInput;
};


export type QueryGetChargeSettingsArgs = {
  input: GetChargeSettingsInput;
};


export type QueryIntegrationAuthorizationUrlArgs = {
  input: IntegrationAuthorizationUrlInput;
};


export type QueryJedlixActiveNotificationsArgs = {
  input?: Maybe<Scalars['Void']>;
};


export type QueryJedlixChargingHistoryArgs = {
  input: JedlixChargingHistoryInput;
};


export type QueryJedlixChargingLocationArgs = {
  input: JedlixChargingLocationInput;
};


export type QueryJedlixChargingLocationsArgs = {
  input?: Maybe<Scalars['Void']>;
};


export type QueryJedlixUserArgs = {
  input: Scalars['Void'];
};


export type QueryListControllablesForUserArgs = {
  input?: Maybe<Scalars['Void']>;
};


export type QueryListJedlixVehiclesArgs = {
  input?: Maybe<Scalars['Void']>;
};


export type QueryMeasurementsArgs = {
  deviceId?: Maybe<Scalars['String']>;
  meterId?: Maybe<Scalars['String']>;
  from: Scalars['String'];
  to: Scalars['String'];
};


export type QueryMyDevicesArgs = {
  input?: Maybe<MyDevicesInput>;
};


export type QueryOrderArgs = {
  input: OrderInput;
};


export type QueryOrderSummaryArgs = {
  input?: Maybe<Scalars['Void']>;
};


export type QueryOrdersForUserArgs = {
  input: OrdersForUserInput;
};


export type QueryPermissionsArgs = {
  scopes: Array<Scalars['String']>;
};


export type QueryPriceZoneArgs = {
  input: PriceZoneInput;
};


export type QueryReportsArgs = {
  deviceId?: Maybe<Scalars['String']>;
  meterId?: Maybe<Scalars['String']>;
  interval: Interval;
  from: Scalars['String'];
  to: Scalars['String'];
  timezone?: Maybe<Timezone>;
};


export type QueryUserArgs = {
  input: UserInput;
};


export type QueryVehicleSmartChargingStatusArgs = {
  input: VehicleSmartChargingStatusInput;
};

export type Range = {
  __typename?: 'Range';
  max: Scalars['Float'];
  min: Scalars['Float'];
  mean: Scalars['Float'];
};

export type RecentMeter = {
  __typename?: 'RecentMeter';
  meterId: Scalars['MeterId'];
  producer: MeterProducer;
  shortMeterId: Scalars['String'];
  firstSeenAt: Scalars['Date'];
};

export type RefurbishDeviceInput = {
  deviceId: Scalars['String'];
  refurbishId?: Maybe<Scalars['String']>;
  parts: RefurbishDevicePartsInput;
};

export type RefurbishDevicePartsInput = {
  pcbaOk: Scalars['Boolean'];
  frontCasingOk: Scalars['Boolean'];
  backCasingOk: Scalars['Boolean'];
  deviceLabelOk: Scalars['Boolean'];
  cardboardBoxOk: Scalars['Boolean'];
  manualOk: Scalars['Boolean'];
  rj45Ok: Scalars['Boolean'];
  usbOk?: Maybe<Scalars['Boolean']>;
  deviceTypeStickerOk: Scalars['Boolean'];
  boxDeviceLabelOk: Scalars['Boolean'];
  sealOk: Scalars['Boolean'];
};

export type RefurbishDevicePayload = {
  __typename?: 'RefurbishDevicePayload';
  device: DeviceInfo;
};

/** Refurbish information for the device. Refurbishing happens after a device is returned. */
export type RefurbishInfo = {
  __typename?: 'RefurbishInfo';
  parts: DeviceRefurbishParts;
  refurbishId: Scalars['String'];
  refurbishedAt: Scalars['Date'];
};

export type RegisterInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  redirectUri?: Maybe<Scalars['String']>;
};

export type RemoveChargingPermissionInput = {
  userIdentifier: UserIdentifierInput;
};

export type RemoveChargingPermissionPayload = {
  __typename?: 'RemoveChargingPermissionPayload';
  modifiedPermission: Permission;
  success: Scalars['Boolean'];
  user: UserInfo;
};

export type RemoveControllableFromLocationInput = {
  locationId: Scalars['String'];
  controllableId: Scalars['String'];
};

export type RemoveControllableFromLocationPayload = {
  __typename?: 'RemoveControllableFromLocationPayload';
  location: Location;
};

export type RemoveDevicePermissionsInput = {
  deviceIdentifiers: Array<DeviceIdentifierInput>;
  userIdentifier: UserIdentifierInput;
};

export type RemoveDevicePermissionsPayload = {
  __typename?: 'RemoveDevicePermissionsPayload';
  modifiedPermissions: Array<Permission>;
  success: Scalars['Boolean'];
  user: UserInfo;
};

export type RemoveIntermediaryApiKeyInput = {
  id: Scalars['String'];
};

export type RemoveIntermediaryUserInput = {
  externalId: Scalars['String'];
};

export type RemoveIntermediaryUserPayload = {
  __typename?: 'RemoveIntermediaryUserPayload';
  user: UserInfo;
};

export type RemoveLocationInput = {
  locationId: Scalars['String'];
};

export type RemoveLocationPayload = {
  __typename?: 'RemoveLocationPayload';
  location: Location;
};

export type RemoveNotificationChannelFromAlertSettingInput = {
  alertSettingId: Scalars['ID'];
  notificationChannelType: NotificationChannelType;
};

export type RemoveNotificationChannelFromAlertSettingPayload = {
  __typename?: 'RemoveNotificationChannelFromAlertSettingPayload';
  success: Scalars['Boolean'];
};

export type RemoveUserInput = {
  userIdentifier: UserIdentifierInput;
};

export type RemoveUserPayload = {
  __typename?: 'RemoveUserPayload';
  success: Scalars['Boolean'];
  user: UserInfo;
};

export type Report = {
  __typename?: 'Report';
  /** Average of ActivePowerImport values in the time period, in W */
  averageActivePowerImport?: Maybe<Scalars['Float']>;
  /**
   * Active energy imported in the time period, in Wh
   *
   * Not available for the _current hour_
   */
  activeEnergyImported?: Maybe<Scalars['Float']>;
  /** Average of ActivePowerExport values in the time period, in W */
  averageActivePowerExport?: Maybe<Scalars['Float']>;
  /**
   * Active energy exported in the time period, in Wh
   *
   * Not available for the _current hour_
   */
  activeEnergyExported?: Maybe<Scalars['Float']>;
  /** Cumulative values of the active energy import in Wh at the start of the interval */
  cumulativeActiveEnergyImportedAtIntervalStart?: Maybe<Scalars['Float']>;
  /** Cumulative values of the active energy export in Wh at the start of the interval */
  cumulativeActiveEnergyExportedAtIntervalStart?: Maybe<Scalars['Float']>;
  /** Cumulative values of the reactive energy import in varh at the start of the interval */
  cumulativeReactiveEnergyImportedAtIntervalStart?: Maybe<Scalars['Float']>;
  /** Cumulative values of the reactive energy export in varh at the start of the interval */
  cumulativeReactiveEnergyExportedAtIntervalStart?: Maybe<Scalars['Float']>;
  /** Report end time, in UTC */
  to: Scalars['Date'];
  /** Report start time, in UTC */
  from: Scalars['Date'];
};

export type ReturnDeviceInput = {
  deviceId: Scalars['String'];
  hasBeenUsed: Scalars['Boolean'];
  comment?: Maybe<Scalars['String']>;
};

export type ReturnDevicePayload = {
  __typename?: 'ReturnDevicePayload';
  device: DeviceInfo;
  success: Scalars['Boolean'];
};

/** Return information */
export type ReturnInfo = {
  __typename?: 'ReturnInfo';
  hasBeenUsed?: Maybe<Scalars['Boolean']>;
  returnedAt?: Maybe<Scalars['Date']>;
  comment?: Maybe<Scalars['String']>;
};

export type RevokeDeviceAccessInput = {
  deviceId: Scalars['String'];
  userId: Scalars['String'];
};

export type RushPricingDay = {
  __typename?: 'RushPricingDay';
  hours: HourRange;
  price: PriceSpecification;
};

/** The additional rush prices in a list, between two dates */
export type RushPricingPeriod = {
  __typename?: 'RushPricingPeriod';
  dateRange: DateRange;
  holidaysIncluded: Scalars['Boolean'];
  days: RushPricingWeek;
};

export type RushPricingWeek = {
  __typename?: 'RushPricingWeek';
  monday?: Maybe<RushPricingDay>;
  tuesday?: Maybe<RushPricingDay>;
  wednesday?: Maybe<RushPricingDay>;
  thursday?: Maybe<RushPricingDay>;
  friday?: Maybe<RushPricingDay>;
  saturday?: Maybe<RushPricingDay>;
  sunday?: Maybe<RushPricingDay>;
};

export type Search = {
  query?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['String']>;
  meterId?: Maybe<Scalars['String']>;
  externalUserId?: Maybe<Scalars['String']>;
};

/** There are different prices for summer and winter, day and night */
export type SeasonalPrices = {
  __typename?: 'SeasonalPrices';
  summer: PricesForPartOfYear;
  winter: PricesForPartOfYear;
};

/** There are different prices for summer and winter, day and night */
export type SeasonalPricesBusiness = {
  __typename?: 'SeasonalPricesBusiness';
  summer: PricesForPartOfYearBusiness;
  winter: PricesForPartOfYearBusiness;
};

export type SeasonalPricesDemandTariff = {
  __typename?: 'SeasonalPricesDemandTariff';
  peaksPerMonth: Scalars['Int'];
  summer: SeasonalPricesDemandTariffSeason;
  winter: SeasonalPricesDemandTariffSeason;
  /**
   * Only used for a couple of companies, which will skip counting night/weekends
   * when finding the peak hour(s) of the month
   */
  highDemandHoursForWorkDays?: Maybe<HourRange>;
};

export type SeasonalPricesDemandTariffSeason = {
  __typename?: 'SeasonalPricesDemandTariffSeason';
  months: MonthRange;
  pricePerKw: PriceSpecification;
};

/** There are different prices for summer and winter, but not day and night */
export type SeasonalPricesWithoutDayAndNight = {
  __typename?: 'SeasonalPricesWithoutDayAndNight';
  summer: PricesForPartOfYearWithoutDayAndNight;
  winter: PricesForPartOfYearWithoutDayAndNight;
};

/** There are different prices for summer and winter, but not day and night */
export type SeasonalPricesWithoutDayAndNightBusiness = {
  __typename?: 'SeasonalPricesWithoutDayAndNightBusiness';
  summer: PricesForPartOfYearWithoutDayAndNightBusiness;
  winter: PricesForPartOfYearWithoutDayAndNightBusiness;
};

export type SeasonalTieredDemandTariff = {
  __typename?: 'SeasonalTieredDemandTariff';
  summer: TieredSeason;
  winter: TieredSeason;
  peaksPerMonth: Scalars['Int'];
  /**
   * Only used for a couple of companies, which will skip counting night/weekends
   * when finding the peak hour(s) of the month
   */
  highDemandHoursForWorkDays?: Maybe<HourRange>;
};

/**
 * Energy model were users can select type of energy model.
 *
 * Companies might have multiple types of energy models, and
 * let users select which model they would like use.
 */
export type SelectableEnergyModel = {
  __typename?: 'SelectableEnergyModel';
  type: EnergyModelType;
  constantPrice?: Maybe<ConstantPrice>;
  differentPricesSeasonal?: Maybe<DifferentPricesSeasonal>;
  differentPricesSeasonalDayAndNight?: Maybe<DifferentPricesSeasonalDayAndNight>;
  differentPricesDayAndNight?: Maybe<DifferentPricesDayAndNight>;
};

/**
 * Energy model were users can select type of energy model.
 *
 * Companies might have multiple types of energy models, and
 * let users select which model they would like use.
 */
export type SelectableEnergyModelBusiness = {
  __typename?: 'SelectableEnergyModelBusiness';
  type: EnergyModelBusinessType;
  constantPrice?: Maybe<ConstantPriceBusiness>;
  demandChargeStepWithSeasons?: Maybe<DemandChargeStepWithSeasonsBusiness>;
  differentPricesSeasonal?: Maybe<DifferentPricesSeasonalBusiness>;
};

export type SelectablePhasePrices = {
  __typename?: 'SelectablePhasePrices';
  voltage230: SelectablePhaseVoltagePrices;
  voltage400: SelectablePhaseVoltagePrices;
};

export type SelectablePhasePricesBusiness = {
  __typename?: 'SelectablePhasePricesBusiness';
  voltage230: SelectablePhaseVoltagePricesBusiness;
  voltage400: SelectablePhaseVoltagePricesBusiness;
};

export type SelectablePhaseVoltagePrices = {
  __typename?: 'SelectablePhaseVoltagePrices';
  currentRangesWithSelectable: Array<CurrentRangeWithSelectable>;
};

export type SelectablePhaseVoltagePricesBusiness = {
  __typename?: 'SelectablePhaseVoltagePricesBusiness';
  currentRangesWithSelectable: Array<CurrentRangeWithSelectableBusiness>;
  energyModelAboveCurrentRanges?: Maybe<SelectableEnergyModelBusiness>;
};

export type SelectableSeasonal = {
  __typename?: 'SelectableSeasonal';
  regular: PricesAndTaxes;
  seasonal: EnergyModelSeasonalConstant;
};

export type SentAlertAlertType =
  | 'HOURLY_CONSUMPTION_LIMIT_ESTIMATION_WARNING'
  | 'ESTIMATED_HOURLY_ACTIVE_ENERGY_LIMIT_WARNING'
  | 'PRICE_WARNING';

export type SessionInfo = {
  __typename?: 'SessionInfo';
  accessToken: Scalars['String'];
  userId: Scalars['String'];
};

export type SessionInfoWithoutAccessToken = {
  __typename?: 'SessionInfoWithoutAccessToken';
  startOfSecret: Scalars['String'];
  userId: Scalars['String'];
};

export type SetCoordinatesForLocationInput = {
  coordinates: LatLngInput;
  locationId: Scalars['String'];
};

export type SetCoordinatesForLocationPayload = {
  __typename?: 'SetCoordinatesForLocationPayload';
  location: Location;
};

export type SetDeviceActivationStatusInput = {
  deviceIdentifier: DeviceIdentifierInput;
  activationStatus: ActivationStatus;
};

export type SetDeviceActivationStatusPayload = {
  __typename?: 'SetDeviceActivationStatusPayload';
  device: DeviceInfo;
  success: Scalars['Boolean'];
};

export type SetDeviceForLocationInput = {
  deviceIdentifier: DeviceQueryIdentifier;
  locationId: Scalars['String'];
};

export type SetDeviceForLocationPayload = {
  __typename?: 'SetDeviceForLocationPayload';
  location: Location;
};

export type SetEnergyConsumptionLimitForLocationInput = {
  locationId: Scalars['String'];
  limit: Scalars['Int'];
};

export type SetEnergyConsumptionLimitForLocationPayload = {
  __typename?: 'SetEnergyConsumptionLimitForLocationPayload';
  location: Location;
};

export type SetIntermediaryWebhookSettingInput = {
  secret: Scalars['String'];
  intermediaryId?: Maybe<Scalars['String']>;
};

export type SetIntermediaryWebhookSettingPayload = {
  __typename?: 'SetIntermediaryWebhookSettingPayload';
  webhookSetting: IntermediaryWebhookSetting;
};

export type SetNameForLocationInput = {
  locationId: Scalars['String'];
  name: Scalars['String'];
};

export type SetNameForLocationPayload = {
  __typename?: 'SetNameForLocationPayload';
  location: Location;
};

export type ShipDeviceIdentifierInput = {
  claimId?: Maybe<Scalars['ClaimId']>;
  deviceId?: Maybe<Scalars['DeviceId']>;
};

export type ShipOrderInput = {
  deviceIdentifier: ShipDeviceIdentifierInput;
  orderId: Scalars['String'];
  trackingLink: Scalars['String'];
};

export type ShipToUserInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  postalAddress: PostalAddressInput;
  phoneNumber?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  externalUserId?: Maybe<Scalars['String']>;
  trackingLink?: Maybe<Scalars['String']>;
};

/** User information for tracking shipment and activation */
export type ShippedToUserInfo = {
  __typename?: 'ShippedToUserInfo';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  postalAddress: PostalAddress;
  phoneNumber?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  externalUserId?: Maybe<Scalars['String']>;
  trackingLink?: Maybe<Scalars['String']>;
};

export type SmartChargingConsideration = {
  __typename?: 'SmartChargingConsideration';
  isCharging: Scalars['Boolean'];
  isPluggedIn: Scalars['Boolean'];
  hasTimeEstimate: Scalars['Boolean'];
  recentlyAtChargingLocation: Scalars['Boolean'];
};

export type SmartChargingPlan = {
  __typename?: 'SmartChargingPlan';
  estimatedFinishAt: Scalars['String'];
  startAt?: Maybe<Scalars['String']>;
  stopConfirmedAt?: Maybe<Scalars['String']>;
  startConfirmedAt?: Maybe<Scalars['String']>;
  endedAt?: Maybe<Scalars['String']>;
  nonSmartCost: Scalars['Float'];
  smartCost?: Maybe<Scalars['Float']>;
};

export type SmartChargingPlanningStatusType =
  | 'PLAN_ENDED_DEADLINE_CHANGED'
  | 'PLAN_ENDED_DISABLED'
  | 'PLAN_ENDED_FAILED'
  | 'PLAN_ENDED_FINISHED'
  | 'PLAN_ENDED_UNPLUGGED'
  | 'PLAN_EXECUTING_CHARGE_INTERRUPTED'
  | 'PLAN_EXECUTING_STOPPED_AWAITING_PRICES'
  | 'PLAN_EXECUTING_STARTED'
  | 'PLAN_EXECUTING_STARTING'
  | 'PLAN_EXECUTING_START_FAILED'
  | 'PLAN_EXECUTING_STOPPED'
  | 'PLAN_EXECUTING_STOPPING'
  | 'PLAN_EXECUTING_STOP_FAILED'
  | 'PLAN_EXECUTING_OVERRIDDEN';

export type SmartChargingPolicy = {
  __typename?: 'SmartChargingPolicy';
  deadlineInUtcTime: Scalars['String'];
  isEnabled: Scalars['Boolean'];
};

export type SmartChargingStatusType =
  | 'DISABLED'
  | 'FULLY_CHARGED'
  | 'CONSIDERING'
  | 'PLANNED';

export type StartOperationalCheckInput = {
  deviceIdentifier: DeviceIdentifierInput;
};

export type StartOperationalCheckPayload = {
  __typename?: 'StartOperationalCheckPayload';
  device: DeviceInfo;
  success: Scalars['Boolean'];
};

export type SubscriptionBilling = {
  __typename?: 'SubscriptionBilling';
  subscriptionType: SubscriptionType;
};

export type SubscriptionInput = {
  subscriptionType?: Maybe<SubscriptionType>;
};

export type SubscriptionType =
  | 'PERSONAL'
  | 'BUSINESS'
  | 'PILOT';

export type TestWebhookSettingInput = {
  callbackUrl: Scalars['String'];
  intermediaryId?: Maybe<Scalars['String']>;
};

export type TestWebhookSettingPayload = {
  __typename?: 'TestWebhookSettingPayload';
  success: Scalars['Boolean'];
};

export type TieredDemandTariff = {
  __typename?: 'TieredDemandTariff';
  demandTariffTiers: Array<DemandTariffTier>;
  priceAboveDemandTariffTiers: PriceSpecification;
  peaksPerMonth: Scalars['Int'];
  /**
   * Only used for a couple of companies, which will skip counting night/weekends
   * when finding the peak hour(s) of the month
   */
  highDemandHoursForWorkDays?: Maybe<HourRange>;
};

export type TieredSeason = {
  __typename?: 'TieredSeason';
  months: MonthRange;
  demandTariffTiers: Array<DemandTariffTier>;
  priceAboveDemandTariffTiers: PriceSpecification;
};

/** Time zones, as reported by the _Internet Assigned Numbers Authority_ (IANA) database, but using an underscore for the forward slash. */
export type Timezone =
  | 'EUROPE_OSLO'
  | 'UTC';

/**
 * Input object for updating charge settings.
 * - `defaultStartBatteryLevel` can be set as a default battery level for the beginning of a session, if we cannot get the SoC from the vehicle this value will be used.
 * - `departureTimes` is a list of departure times for each day of the week.
 * - `desiredBatteryLevel` is the battery level at which the car should be charged at the departure time.
 * - `directChargingBatteryLevel` is the battery level at which the car should start charging immediately.
 * - `enableSmartChargingAt` is the date at which smart charging should be enabled.
 * - `isSmartChargingEnabled` is whether smart charging is enabled.
 * - `isSolarChargingEnabled` is whether solar charging is enabled.
 * - `maxAllowedTargetSoC` is the maximum allowed target state of charge.
 * - `timeZone` is the time zone of the user.
 * - `vehicleId` is the id of the vehicle.
 */
export type UpdateChargeSettingsInput = {
  defaultStartBatteryLevel?: Maybe<Scalars['Float']>;
  departureTimes: DepartureTimesInput;
  desiredBatteryLevel: Scalars['Float'];
  directChargingBatteryLevel: Scalars['Float'];
  enableSmartChargingAt?: Maybe<Scalars['Date']>;
  id: Scalars['String'];
  isSmartChargingEnabled: Scalars['Boolean'];
  isSolarChargingEnabled: Scalars['Boolean'];
  maxAllowedTargetSoC: Scalars['Float'];
  timeZone: Timezone;
  vehicleId: Scalars['String'];
};

/** Response object when updating charge settings. */
export type UpdateChargeSettingsPayload = {
  __typename?: 'UpdateChargeSettingsPayload';
  chargeSettings: ChargeSettings;
};

export type UpdateChargingLocationInput = {
  id: Scalars['ID'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  name: Scalars['String'];
};

export type UpdateChargingLocationPayload = {
  __typename?: 'UpdateChargingLocationPayload';
  chargingLocation: ChargingLocation;
};

export type UpdateEstimatedHourlyActiveEnergyLimitWarningAlertSettingInput = {
  alertSettingId: Scalars['ID'];
  limitInWattHours: Scalars['Int'];
};

export type UpdateEstimatedHourlyActiveEnergyLimitWarningAlertSettingPayload = {
  __typename?: 'UpdateEstimatedHourlyActiveEnergyLimitWarningAlertSettingPayload';
  alertSetting: EstimatedHourlyActiveEnergyLimitWarningAlertSetting;
};

export type UpdateHourlyConsumptionLimitEstimationWarningAlertSettingInput = {
  alertSettingId: Scalars['ID'];
  limitInWatts: Scalars['Int'];
};

export type UpdateHourlyConsumptionLimitEstimationWarningAlertSettingPayload = {
  __typename?: 'UpdateHourlyConsumptionLimitEstimationWarningAlertSettingPayload';
  alertSetting: HourlyConsumptionLimitEstimationWarningAlertSetting;
};

export type UpdateJedlixChargingLocationInput = {
  chargingLocationId: Scalars['String'];
  coordinates: LatLngInput;
  address: JedlixAddressInput;
};

export type UpdateSmartChargingPolicyInput = {
  /**
   * Time for when the charging should be finished by.
   * hours:minutes, e.g. 08:00 or 23:59, in UTC time
   */
  deadlineInUtcTime: Scalars['String'];
  isEnabled: Scalars['Boolean'];
  vehicleId: Scalars['String'];
};

export type UpdateSmartChargingPolicyPayload = {
  __typename?: 'UpdateSmartChargingPolicyPayload';
  smartChargingPolicy: SmartChargingPolicy;
};

export type User = {
  __typename?: 'User';
  id: Scalars['String'];
  verifiedIdentities?: Maybe<Array<Identity>>;
};

export type UserIdentifier = ExternalUserIdentifier;

export type UserIdentifierInput = {
  externalUser?: Maybe<ExternalUserIdentifierInput>;
};

export type UserInfo = {
  __typename?: 'UserInfo';
  alertSettings?: Maybe<Array<AlertSetting>>;
  chargingLocations?: Maybe<Array<ChargingLocation>>;
  id: Scalars['ID'];
  intermediaries?: Maybe<Array<IntermediaryInfo>>;
  linkedIntegrationVendors?: Maybe<Array<IntegrationVendorInfo>>;
  verifiedEmail?: Maybe<Scalars['String']>;
  verifiedIdentities?: Maybe<Array<Identity>>;
  sessions?: Maybe<Array<SessionInfoWithoutAccessToken>>;
  userIdentifier?: Maybe<UserIdentifier>;
};


export type UserInfoIntermediariesArgs = {
  filter?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserInput = {
  userIdentifier: UserIdentifierInput;
};

export type UserNotAuthenticatedError = CustomError & {
  __typename?: 'UserNotAuthenticatedError';
  message: Scalars['String'];
  type: ErrorType;
};

export type UserNotPermittedError = CustomError & {
  __typename?: 'UserNotPermittedError';
  message: Scalars['String'];
  type: ErrorType;
  resourceId?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['String']>;
  scope?: Maybe<Scalars['String']>;
};

export type UserPayload = {
  __typename?: 'UserPayload';
  user: UserInfo;
};

export type VehicleCapabilities = {
  __typename?: 'VehicleCapabilities';
  geoLocation: Scalars['Boolean'];
  desiredStateOfCharge: Scalars['Boolean'];
  stateOfCharge: Scalars['Boolean'];
  chargeState: Scalars['Boolean'];
  pullTelemetry: Scalars['Boolean'];
  startStopCharging: Scalars['Boolean'];
  charge: Scalars['Boolean'];
};

export type VehicleChargingStatus =
  | 'UNKNOWN'
  | 'COMPLETE'
  | 'CHARGING'
  | 'DISCONNECTED'
  | 'STOPPED';

export type VehicleInfo = {
  __typename?: 'VehicleInfo';
  batteryCapacity: Scalars['Float'];
  brand: JedlixBrand;
  range: Scalars['Float'];
  model: Scalars['String'];
  version: Scalars['String'];
};

export type VehicleSmartChargingStatusInput = {
  vehicleId: Scalars['String'];
};

export type VehicleSmartChargingStatusPayload = DisabledSmartChargingStatus | ConsideringSmartChargingStatus | PlannedSmartChargingStatus | FullyChargedSmartChargingStatus;

export type Vendor =
  | 'MILL'
  | 'ADAX';


/**
 * Capacity model used by Fjellnett/Eidefoss
 *
 * Computation steps, according to <https://www.fjellnett.no/privatkunder/category1153.html>:
 *
 * - Fetch consumption data for each hour for the previous twelve months.
 * - Multiply the consumption for each hour with the corresponding month weights.
 * - Find the peak value of every week.
 * - Pick the highest (5) `weekPeaksPerYear` and compute the average for these (with two decimals).
 * - Multiply by `pricePerAverageKwh`.
 * - Add `baseFeePerYear`.
 * - Divide by 12.
 */
export type WeightedYearlyRollingPeaksInDifferentWeeks = {
  __typename?: 'WeightedYearlyRollingPeaksInDifferentWeeks';
  type: CapacityModelType;
  baseFeePerYear: PriceSpecification;
  monthWeights: MonthWeights;
  pricePerKwhForAverageOfPeaks: PriceSpecification;
  weekPeaksPerYear: Scalars['Int'];
};

/**
 * Capacity model used by Fjellnett/Eidefoss
 *
 * Computation steps, according to <https://www.fjellnett.no/privatkunder/category1153.html>:
 *
 * - Fetch consumption data for each hour for the previous twelve months.
 * - Multiply the consumption for each hour with the corresponding month weights.
 * - Find the peak value of every week.
 * - Pick the highest (5) `weekPeaksPerYear` and compute the average for these (with two decimals).
 * - Multiply by `pricePerAverageKwh`.
 * - Add `baseFeePerYear`.
 * - Divide by 12.
 */
export type WeightedYearlyRollingPeaksInDifferentWeeksBusiness = {
  __typename?: 'WeightedYearlyRollingPeaksInDifferentWeeksBusiness';
  type: CapacityModelBusinessType;
  baseFeePerYear: PriceSpecification;
  monthWeights: MonthWeights;
  pricePerKwhForAverageOfPeaks: PriceSpecification;
  weekPeaksPerYear: Scalars['Int'];
  yearlyEnergyFund: PriceSpecification;
};

export type ZaptecClaimInstallationInput = {
  installationId: Scalars['String'];
};

export type ZaptecClaimInstallationPayload = {
  __typename?: 'ZaptecClaimInstallationPayload';
  success: Scalars['Boolean'];
};

export type IntegrationAuthorizationUrlQueryVariables = Exact<{
  input: IntegrationAuthorizationUrlInput;
}>;


export type IntegrationAuthorizationUrlQuery = (
  { __typename?: 'Query' }
  & { integrationAuthorizationUrl: (
    { __typename?: 'IntegrationAuthorizationUrlResponse' }
    & Pick<IntegrationAuthorizationUrlResponse, 'authorizationUrl'>
  ) }
);

export type ChargingStatisticsQueryVariables = Exact<{
  input: ChargingStatisticsInput;
}>;


export type ChargingStatisticsQuery = (
  { __typename?: 'Query' }
  & { chargingStatistics: (
    { __typename?: 'ChargingStatisticsPayload' }
    & { entries: Array<(
      { __typename?: 'ChargingStatisticsEntry' }
      & Pick<ChargingStatisticsEntry, 'kwhSum' | 'priceCurrency' | 'costSum' | 'date' | 'smartSession' | 'estimatedSavings'>
      & { kw: (
        { __typename?: 'Range' }
        & Pick<Range, 'max' | 'min' | 'mean'>
      ), price: (
        { __typename?: 'Range' }
        & Pick<Range, 'max' | 'min' | 'mean'>
      ), nonSmartPrice: (
        { __typename?: 'Range' }
        & Pick<Range, 'max' | 'min' | 'mean'>
      ) }
    )> }
  ) }
);

export type MyIntegrationsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyIntegrationsQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'UserInfo' }
    & { chargingLocations?: Maybe<Array<(
      { __typename?: 'ChargingLocation' }
      & Pick<ChargingLocation, 'id' | 'latitude' | 'longitude' | 'name'>
    )>>, linkedIntegrationVendors?: Maybe<Array<(
      { __typename: 'ConfiguredIntegrationVendorInfo' }
      & Pick<ConfiguredIntegrationVendorInfo, 'vendor'>
      & { devices: Array<(
        { __typename: 'ConfiguredIntegrationVendorVehicle' }
        & Pick<ConfiguredIntegrationVendorVehicle, 'id' | 'model' | 'yearOfProduction' | 'vendor' | 'isReachable' | 'isSmartChargingCapable' | 'chargingLocationId'>
        & { location: (
          { __typename: 'GpsNeverActivatedConfiguredIntegrationVendorVehicleLocationState' }
          & Pick<GpsNeverActivatedConfiguredIntegrationVendorVehicleLocationState, 'type'>
        ) | (
          { __typename: 'GpsDisabledConfiguredIntegrationVendorVehicleLocationState' }
          & Pick<GpsDisabledConfiguredIntegrationVendorVehicleLocationState, 'type'>
        ) | (
          { __typename: 'LocationAvailableConfiguredIntegrationVendorVehicleLocationState' }
          & Pick<LocationAvailableConfiguredIntegrationVendorVehicleLocationState, 'type' | 'latitude' | 'longitude' | 'lastUpdatedAt'>
        ), chargeState: (
          { __typename?: 'ConfiguredIntegrationVendorVehicleChargeState' }
          & Pick<ConfiguredIntegrationVendorVehicleChargeState, 'batteryCapacity' | 'batteryLevel' | 'chargeRate' | 'chargeTimeRemaining' | 'range' | 'isCharging' | 'isPluggedIn' | 'isFullyCharged'>
        ), smartChargingPolicy?: Maybe<(
          { __typename?: 'SmartChargingPolicy' }
          & Pick<SmartChargingPolicy, 'deadlineInUtcTime' | 'isEnabled'>
        )> }
      )> }
    ) | (
      { __typename: 'NotConfiguredIntegrationVendorInfo' }
      & Pick<NotConfiguredIntegrationVendorInfo, 'vendor'>
    )>> }
  ) }
);

export type AddChargingLocationMutationVariables = Exact<{
  input: AddChargingLocationInput;
}>;


export type AddChargingLocationMutation = (
  { __typename?: 'Mutation' }
  & { addChargingLocation: (
    { __typename: 'AddChargingLocationSuccessPayload' }
    & Pick<AddChargingLocationSuccessPayload, 'type'>
    & { chargingLocation: (
      { __typename?: 'ChargingLocation' }
      & Pick<ChargingLocation, 'latitude' | 'longitude' | 'name' | 'id'>
    ) }
  ) | (
    { __typename: 'ChargingLocationAlreadyExistsPayload' }
    & Pick<ChargingLocationAlreadyExistsPayload, 'type'>
  ) }
);

export type DeleteChargingLocationMutationVariables = Exact<{
  input: DeleteChargingLocationInput;
}>;


export type DeleteChargingLocationMutation = (
  { __typename?: 'Mutation' }
  & { deleteChargingLocation: (
    { __typename?: 'DeleteChargingLocationPayload' }
    & Pick<DeleteChargingLocationPayload, 'success'>
  ) }
);

export type UpdateChargingLocationMutationVariables = Exact<{
  input: UpdateChargingLocationInput;
}>;


export type UpdateChargingLocationMutation = (
  { __typename?: 'Mutation' }
  & { updateChargingLocation: (
    { __typename?: 'UpdateChargingLocationPayload' }
    & { chargingLocation: (
      { __typename?: 'ChargingLocation' }
      & Pick<ChargingLocation, 'id' | 'latitude' | 'longitude' | 'name'>
    ) }
  ) }
);

export type DisconnectVendorMutationVariables = Exact<{
  input: DisconnectVendorInput;
}>;


export type DisconnectVendorMutation = (
  { __typename?: 'Mutation' }
  & { disconnectVendor: (
    { __typename?: 'DisconnectVendorPayload' }
    & Pick<DisconnectVendorPayload, 'success'>
  ) }
);

export type VehicleSmartChargingStatusQueryVariables = Exact<{
  input: VehicleSmartChargingStatusInput;
}>;


export type VehicleSmartChargingStatusQuery = (
  { __typename?: 'Query' }
  & { vehicleSmartChargingStatus: (
    { __typename: 'DisabledSmartChargingStatus' }
    & Pick<DisabledSmartChargingStatus, 'type' | 'externalStart'>
  ) | (
    { __typename: 'ConsideringSmartChargingStatus' }
    & Pick<ConsideringSmartChargingStatus, 'type' | 'externalStart'>
    & { consideration: (
      { __typename?: 'SmartChargingConsideration' }
      & Pick<SmartChargingConsideration, 'recentlyAtChargingLocation' | 'hasTimeEstimate' | 'isCharging' | 'isPluggedIn'>
    ) }
  ) | (
    { __typename: 'PlannedSmartChargingStatus' }
    & Pick<PlannedSmartChargingStatus, 'type' | 'planningStatus' | 'externalStart'>
    & { plan: (
      { __typename?: 'SmartChargingPlan' }
      & Pick<SmartChargingPlan, 'estimatedFinishAt' | 'startAt' | 'endedAt' | 'smartCost' | 'nonSmartCost' | 'stopConfirmedAt' | 'startConfirmedAt'>
    ) }
  ) | (
    { __typename: 'FullyChargedSmartChargingStatus' }
    & Pick<FullyChargedSmartChargingStatus, 'type' | 'externalStart'>
  ) }
);

export type UpdateSmartChargingPolicyMutationVariables = Exact<{
  input: UpdateSmartChargingPolicyInput;
}>;


export type UpdateSmartChargingPolicyMutation = (
  { __typename?: 'Mutation' }
  & { updateSmartChargingPolicy: (
    { __typename?: 'UpdateSmartChargingPolicyPayload' }
    & { smartChargingPolicy: (
      { __typename?: 'SmartChargingPolicy' }
      & Pick<SmartChargingPolicy, 'deadlineInUtcTime' | 'isEnabled'>
    ) }
  ) }
);

export type GetDayAheadPricesQueryVariables = Exact<{
  from: Scalars['Date'];
  to: Scalars['Date'];
  zone: EnergyBiddingZone;
}>;


export type GetDayAheadPricesQuery = (
  { __typename?: 'Query' }
  & { dayAheadPrices: Array<Maybe<(
    { __typename?: 'EnergyPriceOfHour' }
    & Pick<EnergyPriceOfHour, 'to' | 'from'>
    & { priceSpecification?: Maybe<(
      { __typename?: 'PriceSpecification' }
      & Pick<PriceSpecification, 'price'>
    )> }
  )>> }
);


export const IntegrationAuthorizationUrlDocument = gql`
    query IntegrationAuthorizationUrl($input: IntegrationAuthorizationUrlInput!) {
  integrationAuthorizationUrl(input: $input) {
    authorizationUrl
  }
}
    `;

/**
 * __useIntegrationAuthorizationUrlQuery__
 *
 * To run a query within a React component, call `useIntegrationAuthorizationUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntegrationAuthorizationUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntegrationAuthorizationUrlQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIntegrationAuthorizationUrlQuery(baseOptions: Apollo.QueryHookOptions<IntegrationAuthorizationUrlQuery, IntegrationAuthorizationUrlQueryVariables>) {
        return Apollo.useQuery<IntegrationAuthorizationUrlQuery, IntegrationAuthorizationUrlQueryVariables>(IntegrationAuthorizationUrlDocument, baseOptions);
      }
export function useIntegrationAuthorizationUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IntegrationAuthorizationUrlQuery, IntegrationAuthorizationUrlQueryVariables>) {
          return Apollo.useLazyQuery<IntegrationAuthorizationUrlQuery, IntegrationAuthorizationUrlQueryVariables>(IntegrationAuthorizationUrlDocument, baseOptions);
        }
export type IntegrationAuthorizationUrlQueryHookResult = ReturnType<typeof useIntegrationAuthorizationUrlQuery>;
export type IntegrationAuthorizationUrlLazyQueryHookResult = ReturnType<typeof useIntegrationAuthorizationUrlLazyQuery>;
export type IntegrationAuthorizationUrlQueryResult = Apollo.QueryResult<IntegrationAuthorizationUrlQuery, IntegrationAuthorizationUrlQueryVariables>;
export const ChargingStatisticsDocument = gql`
    query chargingStatistics($input: ChargingStatisticsInput!) {
  chargingStatistics(input: $input) {
    entries {
      kw {
        max
        min
        mean
      }
      kwhSum
      price {
        max
        min
        mean
      }
      nonSmartPrice {
        max
        min
        mean
      }
      priceCurrency
      costSum
      date
      smartSession
      estimatedSavings
    }
  }
}
    `;

/**
 * __useChargingStatisticsQuery__
 *
 * To run a query within a React component, call `useChargingStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChargingStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChargingStatisticsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChargingStatisticsQuery(baseOptions: Apollo.QueryHookOptions<ChargingStatisticsQuery, ChargingStatisticsQueryVariables>) {
        return Apollo.useQuery<ChargingStatisticsQuery, ChargingStatisticsQueryVariables>(ChargingStatisticsDocument, baseOptions);
      }
export function useChargingStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChargingStatisticsQuery, ChargingStatisticsQueryVariables>) {
          return Apollo.useLazyQuery<ChargingStatisticsQuery, ChargingStatisticsQueryVariables>(ChargingStatisticsDocument, baseOptions);
        }
export type ChargingStatisticsQueryHookResult = ReturnType<typeof useChargingStatisticsQuery>;
export type ChargingStatisticsLazyQueryHookResult = ReturnType<typeof useChargingStatisticsLazyQuery>;
export type ChargingStatisticsQueryResult = Apollo.QueryResult<ChargingStatisticsQuery, ChargingStatisticsQueryVariables>;
export const MyIntegrationsDocument = gql`
    query MyIntegrations {
  me {
    chargingLocations {
      id
      latitude
      longitude
      name
    }
    linkedIntegrationVendors {
      __typename
      ... on ConfiguredIntegrationVendorInfo {
        vendor
        devices {
          __typename
          ... on ConfiguredIntegrationVendorVehicle {
            id
            location {
              __typename
              ... on LocationAvailableConfiguredIntegrationVendorVehicleLocationState {
                type
                latitude
                longitude
                lastUpdatedAt
              }
              ... on GpsNeverActivatedConfiguredIntegrationVendorVehicleLocationState {
                type
              }
              ... on GpsDisabledConfiguredIntegrationVendorVehicleLocationState {
                type
              }
            }
            model
            yearOfProduction
            vendor
            chargeState {
              batteryCapacity
              batteryLevel
              chargeRate
              chargeTimeRemaining
              range
              isCharging
              isPluggedIn
              isFullyCharged
            }
            smartChargingPolicy {
              deadlineInUtcTime
              isEnabled
            }
            isReachable
            isSmartChargingCapable
            chargingLocationId
          }
        }
      }
      ... on NotConfiguredIntegrationVendorInfo {
        vendor
      }
    }
  }
}
    `;

/**
 * __useMyIntegrationsQuery__
 *
 * To run a query within a React component, call `useMyIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyIntegrationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyIntegrationsQuery(baseOptions?: Apollo.QueryHookOptions<MyIntegrationsQuery, MyIntegrationsQueryVariables>) {
        return Apollo.useQuery<MyIntegrationsQuery, MyIntegrationsQueryVariables>(MyIntegrationsDocument, baseOptions);
      }
export function useMyIntegrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyIntegrationsQuery, MyIntegrationsQueryVariables>) {
          return Apollo.useLazyQuery<MyIntegrationsQuery, MyIntegrationsQueryVariables>(MyIntegrationsDocument, baseOptions);
        }
export type MyIntegrationsQueryHookResult = ReturnType<typeof useMyIntegrationsQuery>;
export type MyIntegrationsLazyQueryHookResult = ReturnType<typeof useMyIntegrationsLazyQuery>;
export type MyIntegrationsQueryResult = Apollo.QueryResult<MyIntegrationsQuery, MyIntegrationsQueryVariables>;
export const AddChargingLocationDocument = gql`
    mutation AddChargingLocation($input: AddChargingLocationInput!) {
  addChargingLocation(input: $input) {
    __typename
    ... on AddChargingLocationSuccessPayload {
      chargingLocation {
        latitude
        longitude
        name
        id
      }
      type
    }
    ... on ChargingLocationAlreadyExistsPayload {
      type
    }
  }
}
    `;
export type AddChargingLocationMutationFn = Apollo.MutationFunction<AddChargingLocationMutation, AddChargingLocationMutationVariables>;

/**
 * __useAddChargingLocationMutation__
 *
 * To run a mutation, you first call `useAddChargingLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddChargingLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addChargingLocationMutation, { data, loading, error }] = useAddChargingLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddChargingLocationMutation(baseOptions?: Apollo.MutationHookOptions<AddChargingLocationMutation, AddChargingLocationMutationVariables>) {
        return Apollo.useMutation<AddChargingLocationMutation, AddChargingLocationMutationVariables>(AddChargingLocationDocument, baseOptions);
      }
export type AddChargingLocationMutationHookResult = ReturnType<typeof useAddChargingLocationMutation>;
export type AddChargingLocationMutationResult = Apollo.MutationResult<AddChargingLocationMutation>;
export type AddChargingLocationMutationOptions = Apollo.BaseMutationOptions<AddChargingLocationMutation, AddChargingLocationMutationVariables>;
export const DeleteChargingLocationDocument = gql`
    mutation DeleteChargingLocation($input: DeleteChargingLocationInput!) {
  deleteChargingLocation(input: $input) {
    success
  }
}
    `;
export type DeleteChargingLocationMutationFn = Apollo.MutationFunction<DeleteChargingLocationMutation, DeleteChargingLocationMutationVariables>;

/**
 * __useDeleteChargingLocationMutation__
 *
 * To run a mutation, you first call `useDeleteChargingLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChargingLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChargingLocationMutation, { data, loading, error }] = useDeleteChargingLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteChargingLocationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteChargingLocationMutation, DeleteChargingLocationMutationVariables>) {
        return Apollo.useMutation<DeleteChargingLocationMutation, DeleteChargingLocationMutationVariables>(DeleteChargingLocationDocument, baseOptions);
      }
export type DeleteChargingLocationMutationHookResult = ReturnType<typeof useDeleteChargingLocationMutation>;
export type DeleteChargingLocationMutationResult = Apollo.MutationResult<DeleteChargingLocationMutation>;
export type DeleteChargingLocationMutationOptions = Apollo.BaseMutationOptions<DeleteChargingLocationMutation, DeleteChargingLocationMutationVariables>;
export const UpdateChargingLocationDocument = gql`
    mutation UpdateChargingLocation($input: UpdateChargingLocationInput!) {
  updateChargingLocation(input: $input) {
    chargingLocation {
      id
      latitude
      longitude
      name
    }
  }
}
    `;
export type UpdateChargingLocationMutationFn = Apollo.MutationFunction<UpdateChargingLocationMutation, UpdateChargingLocationMutationVariables>;

/**
 * __useUpdateChargingLocationMutation__
 *
 * To run a mutation, you first call `useUpdateChargingLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChargingLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChargingLocationMutation, { data, loading, error }] = useUpdateChargingLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateChargingLocationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChargingLocationMutation, UpdateChargingLocationMutationVariables>) {
        return Apollo.useMutation<UpdateChargingLocationMutation, UpdateChargingLocationMutationVariables>(UpdateChargingLocationDocument, baseOptions);
      }
export type UpdateChargingLocationMutationHookResult = ReturnType<typeof useUpdateChargingLocationMutation>;
export type UpdateChargingLocationMutationResult = Apollo.MutationResult<UpdateChargingLocationMutation>;
export type UpdateChargingLocationMutationOptions = Apollo.BaseMutationOptions<UpdateChargingLocationMutation, UpdateChargingLocationMutationVariables>;
export const DisconnectVendorDocument = gql`
    mutation DisconnectVendor($input: DisconnectVendorInput!) {
  disconnectVendor(input: $input) {
    success
  }
}
    `;
export type DisconnectVendorMutationFn = Apollo.MutationFunction<DisconnectVendorMutation, DisconnectVendorMutationVariables>;

/**
 * __useDisconnectVendorMutation__
 *
 * To run a mutation, you first call `useDisconnectVendorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectVendorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectVendorMutation, { data, loading, error }] = useDisconnectVendorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDisconnectVendorMutation(baseOptions?: Apollo.MutationHookOptions<DisconnectVendorMutation, DisconnectVendorMutationVariables>) {
        return Apollo.useMutation<DisconnectVendorMutation, DisconnectVendorMutationVariables>(DisconnectVendorDocument, baseOptions);
      }
export type DisconnectVendorMutationHookResult = ReturnType<typeof useDisconnectVendorMutation>;
export type DisconnectVendorMutationResult = Apollo.MutationResult<DisconnectVendorMutation>;
export type DisconnectVendorMutationOptions = Apollo.BaseMutationOptions<DisconnectVendorMutation, DisconnectVendorMutationVariables>;
export const VehicleSmartChargingStatusDocument = gql`
    query VehicleSmartChargingStatus($input: VehicleSmartChargingStatusInput!) {
  vehicleSmartChargingStatus(input: $input) {
    __typename
    ... on ConsideringSmartChargingStatus {
      type
      consideration {
        recentlyAtChargingLocation
        hasTimeEstimate
        isCharging
        isPluggedIn
      }
      externalStart
    }
    ... on DisabledSmartChargingStatus {
      type
      externalStart
    }
    ... on FullyChargedSmartChargingStatus {
      type
      externalStart
    }
    ... on PlannedSmartChargingStatus {
      type
      planningStatus
      externalStart
      plan {
        estimatedFinishAt
        startAt
        endedAt
        smartCost
        nonSmartCost
        stopConfirmedAt
        startConfirmedAt
      }
    }
  }
}
    `;

/**
 * __useVehicleSmartChargingStatusQuery__
 *
 * To run a query within a React component, call `useVehicleSmartChargingStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleSmartChargingStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleSmartChargingStatusQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVehicleSmartChargingStatusQuery(baseOptions: Apollo.QueryHookOptions<VehicleSmartChargingStatusQuery, VehicleSmartChargingStatusQueryVariables>) {
        return Apollo.useQuery<VehicleSmartChargingStatusQuery, VehicleSmartChargingStatusQueryVariables>(VehicleSmartChargingStatusDocument, baseOptions);
      }
export function useVehicleSmartChargingStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VehicleSmartChargingStatusQuery, VehicleSmartChargingStatusQueryVariables>) {
          return Apollo.useLazyQuery<VehicleSmartChargingStatusQuery, VehicleSmartChargingStatusQueryVariables>(VehicleSmartChargingStatusDocument, baseOptions);
        }
export type VehicleSmartChargingStatusQueryHookResult = ReturnType<typeof useVehicleSmartChargingStatusQuery>;
export type VehicleSmartChargingStatusLazyQueryHookResult = ReturnType<typeof useVehicleSmartChargingStatusLazyQuery>;
export type VehicleSmartChargingStatusQueryResult = Apollo.QueryResult<VehicleSmartChargingStatusQuery, VehicleSmartChargingStatusQueryVariables>;
export const UpdateSmartChargingPolicyDocument = gql`
    mutation UpdateSmartChargingPolicy($input: UpdateSmartChargingPolicyInput!) {
  updateSmartChargingPolicy(input: $input) {
    smartChargingPolicy {
      deadlineInUtcTime
      isEnabled
    }
  }
}
    `;
export type UpdateSmartChargingPolicyMutationFn = Apollo.MutationFunction<UpdateSmartChargingPolicyMutation, UpdateSmartChargingPolicyMutationVariables>;

/**
 * __useUpdateSmartChargingPolicyMutation__
 *
 * To run a mutation, you first call `useUpdateSmartChargingPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSmartChargingPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSmartChargingPolicyMutation, { data, loading, error }] = useUpdateSmartChargingPolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSmartChargingPolicyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSmartChargingPolicyMutation, UpdateSmartChargingPolicyMutationVariables>) {
        return Apollo.useMutation<UpdateSmartChargingPolicyMutation, UpdateSmartChargingPolicyMutationVariables>(UpdateSmartChargingPolicyDocument, baseOptions);
      }
export type UpdateSmartChargingPolicyMutationHookResult = ReturnType<typeof useUpdateSmartChargingPolicyMutation>;
export type UpdateSmartChargingPolicyMutationResult = Apollo.MutationResult<UpdateSmartChargingPolicyMutation>;
export type UpdateSmartChargingPolicyMutationOptions = Apollo.BaseMutationOptions<UpdateSmartChargingPolicyMutation, UpdateSmartChargingPolicyMutationVariables>;
export const GetDayAheadPricesDocument = gql`
    query getDayAheadPrices($from: Date!, $to: Date!, $zone: EnergyBiddingZone!) {
  dayAheadPrices(from: $from, to: $to, zone: $zone) {
    priceSpecification {
      price
    }
    to
    from
  }
}
    `;

/**
 * __useGetDayAheadPricesQuery__
 *
 * To run a query within a React component, call `useGetDayAheadPricesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDayAheadPricesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDayAheadPricesQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      zone: // value for 'zone'
 *   },
 * });
 */
export function useGetDayAheadPricesQuery(baseOptions: Apollo.QueryHookOptions<GetDayAheadPricesQuery, GetDayAheadPricesQueryVariables>) {
        return Apollo.useQuery<GetDayAheadPricesQuery, GetDayAheadPricesQueryVariables>(GetDayAheadPricesDocument, baseOptions);
      }
export function useGetDayAheadPricesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDayAheadPricesQuery, GetDayAheadPricesQueryVariables>) {
          return Apollo.useLazyQuery<GetDayAheadPricesQuery, GetDayAheadPricesQueryVariables>(GetDayAheadPricesDocument, baseOptions);
        }
export type GetDayAheadPricesQueryHookResult = ReturnType<typeof useGetDayAheadPricesQuery>;
export type GetDayAheadPricesLazyQueryHookResult = ReturnType<typeof useGetDayAheadPricesLazyQuery>;
export type GetDayAheadPricesQueryResult = Apollo.QueryResult<GetDayAheadPricesQuery, GetDayAheadPricesQueryVariables>;